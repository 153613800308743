import './App.css';

//General
import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';

//Paginas
import Home from './Home/Home';
import Hablemos from './Hablemos/Hablemos';
import Submenu from './Submenu/Submenu';
import Menu from './Menu/Menu';
import Distribuidora from './Distribuidora/Distribuidora';
import Sucursales from './Sucursales/Sucursales';
import Tienda from './Tienda/Tienda';
import Bolsa from './Bolsa/Bolsa';
import Sesion from './Sesion/Sesion';
import Registro from './Registro/Registro';
import Cuenta from './Cuenta/Cuenta';
import Carrito from './Carrito/Carrito';
import Producto from './Producto/Producto';
import Pagar from './Pagar/Pagar';
import Compra from './Compra/Compra';
import Buzon from './Buzon/Buzon';
import Facturacion from './Facturacion/Facturacion';
import PasswordChange from './PasswordChange/PasswordChange';
import Terminos from './Terminos/Terminos';
import EmailList from './EmailList/EmailList';

//Librerias
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';

function App() {
  const [cart, setCart] = useState(null);

  function getCart(){
    let cartId = localStorage.getItem("cart");
    if(cartId){
      axios.get(process.env.REACT_APP_CMS_URL + '/api/carritos?filters[identificador][$eq]='+cartId+'&filters[active][$eq]=true&populate[productos][populate][0]=galeria').then((response) => {
        if(response?.data?.data[0]) setCart(response?.data?.data[0]);
        else createCart();
      }).catch((error) => {
        console.error(error);
        createCart();
      });
    }
    else{
      createCart();
    }
  }

  function createCart(){
    axios.post(process.env.REACT_APP_API_URL + "/api/cart/create-cart").then((response) => {
      localStorage.setItem("cart", response?.data?.data?.attributes?.identificador);
      setCart(response?.data?.data);
    }).catch((error) => {
      console.error(error);
    });
  }

  function updateCart(newCart, action){
    const body = {
      id: newCart?.id,
      opciones: newCart?.attributes?.opciones,
      productos: newCart?.attributes?.productos?.data
    }
    axios.post(process.env.REACT_APP_API_URL + "/api/cart/update-cart", body).then((response) => {
      if(response.status === 200){
        if(action === "add") notify("Producto agregado al carrito", "success");
        else notify("Producto eliminado", "success")
        getCart();
      }
      else notify("Error: " + response.status, "error");
    }).catch((error) => {
      notify(error?.response?.data?.error, "error");
    });
  }

  const notify = (text, type, promise ) => {
    const options = {
      style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
      },
    }
    switch(type){
      case "error": toast.error(text, options); break;
      case "success": toast.success(text, options); break;
      case "custom": toast.custom(text); break;
      case "loading": toast.loading(text, options); break;
      case "promise": toast.promise(promise, {
        loading: 'Loading',
        success: (data) => `Successfully saved ${data.name}`,
        error: (err) => `This just happened: ${err.toString()}`,
      }, options); break;
      default: toast(text, options); break;
    }
  }

  useEffect(() => {
    if(!cart) getCart();
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <div className='main-container bg-black'>
          <Toaster/>
          <Navbar cart={cart} />
          <Routes>
            <Route path='*' element={ <Navigate to="/" /> }/>
            <Route path='/' element={<Home />} />
            <Route path='/hablemos' element={<Hablemos />} />
            <Route path='/menu' element={<Menu />} />
            <Route path='/menu/:id' element={<Submenu />} />
            <Route path='/sucursales' element={<Sucursales />} />
            <Route path='/tienda' element={<Tienda />} />
            <Route path='/tienda/:id' element={<Producto updateCart={(e, action) => updateCart(e, action)} cart={cart}/>} />
            <Route path='/bolsa' element={<Bolsa />} />
            <Route path='/distribuidora' element={<Distribuidora />} />
            <Route path='/sesion' element={<Sesion />} />
            <Route path='/registro' element={<Registro />} />
            <Route path='/cuenta' element={<Cuenta />} />
            <Route path='/cuenta/cambio-contraseña' element={<PasswordChange />} />
            <Route path='/carrito' element={<Carrito updateCart={(e, action) => updateCart(e, action)} cart={cart}/>} />
            <Route path='/buzon' element={<Buzon />} />
            <Route path='/facturacion' element={<Facturacion />} />
            <Route path='/terminos-y-condiciones' element={<Terminos />} />
            <Route path='/email-list' element={<EmailList />} />
            <Route path='/pagar' element={<Pagar />} />
            <Route path='/compra/:id' element={<Compra />} />
          </Routes>
          <Footer/>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
