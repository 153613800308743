import { Link } from 'react-router-dom';
import axios from "axios";
import React, { useEffect, useState } from "react";

function Tienda() {
  const [productos, setProductos] = useState([]);

  function getProductos() {
    axios
      .get(process.env.REACT_APP_CMS_URL + "/api/productos?populate=*")
      .then((response) => {
        setProductos(response?.data?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    getProductos();
  }, []);

  var _productos = [];
  productos.forEach(producto => {
    _productos.push(
      <div className="col-6 col-lg-2 py-3" key={"producto_"+producto.id}>
        <Link className='text-decoration-none' to={"/tienda/"+producto.id}>
          <div className="card bg-white rounded rounded-4 p-2">
            <div className="d-none d-lg-block" style={{height:"100px"}}>
              <img className="img-fluid" style={{maxHeight: "100px"}} src={process.env.REACT_APP_CMS_URL + producto?.attributes.galeria.data[0].attributes.url} alt="" />
            </div>
            <div className="d-lg-none" style={{height:"200px"}}>
              <img className="img-fluid" style={{maxHeight: "200px"}} src={process.env.REACT_APP_CMS_URL + producto?.attributes.galeria.data[0].attributes.url} alt="" />
            </div>
            <div className="d-none d-lg-block" style={{height:"150px", boxShadow: "0 -10px 10px -5px rgba(0, 0, 0, 0.1)"}}>
              <p className="text-center"><small><strong>{producto.attributes.nombre}</strong></small></p>
              <p className="text-center mb-5"><small>{producto.attributes.subtitulo}</small></p>
            </div>
            <div className="d-lg-none" style={{height:"100px", boxShadow: "0 -10px 10px -5px rgba(0, 0, 0, 0.1)"}}>
              <p className="text-center mb-3"><small><strong>{producto.attributes.nombre}</strong></small></p>
              <p className="text-center mb-5"><small>{producto.attributes.subtitulo}</small></p>
            </div>
          </div>
        </Link>
      </div>
    );
  })

  return (
    <div className="container">
      <div className="row mx-auto">
        {_productos}
      </div>
    </div>
  );
}

export default Tienda;
