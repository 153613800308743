function Bolsa() {
  return (
    <div className="bg-black text-white text-center">
      <div className="container p-5">
        <h4 className="mb-5">Bolsa de Trabajo</h4>
        <div className="row mx-auto align-items-end">
          <div className="col-12 col-lg-7 pb-5">
            <img className="img-fluid" alt="Inno Barras" src="assets/img/bolsa/bolsa.png" />
          </div>
          <div className="col-12 col-lg-5">
            <button className="mb-3 p-3 btn bg black border-white text-white w-100 text-start">
              <div className="row mx-auto">
                <div className="col-3">
                  <img className="d-none d-lg-block" alt="facebook logo" src="./assets/img/icons/fb.png"/>
                  <img className="d-lg-none" alt="facebook logo" src="./assets/img/icons/fb.png"/>
                </div>
                <div className="col-9">
                  Encuentra vacantes aquí
                </div>
              </div>
            </button>
            <button className="mb-3 p-3 btn bg black border-white text-white w-100 text-start">
              <div className="row mx-auto">
                <div className="col-3">
                  <img className="d-none d-lg-block" alt="facebook logo" src="./assets/img/icons/wa.png"/>
                  <img className="d-lg-none" alt="facebook logo" src="./assets/img/icons/wa.png"/>
                </div>
                <div className="col-9">
                Envíanos un mensaje
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bolsa;
