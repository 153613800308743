import React from 'react';
import { ClipLoader } from 'react-spinners';

const Overlay = ({ onClick }) => {
  return (
    <div className="overlay p-5" onClick={onClick}>
        <ClipLoader
            className='m-5 p-5'
            color={"#FFFFFF"}
            loading={true}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
        />
    </div>
  );
}

export default Overlay;
