import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from 'react-hot-toast';
import AddressForm from "../AddressForm/AddressForm";
import Overlay from "../Overlay/Overlay";

function Carrito(props) {
  const [shippingPrice, setShippingPrice] = useState("0");
  const [domicilios, setDomicilios] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [addressInformation, setAddressInformation] = useState(null);

  var _items = [];
  var total = 0;

  console.log("Cart", props?.cart);

  const notify = (text, type, promise) => {
    const options = {
      style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
      },
    }
    switch(type){
      case "error": toast.error(text, options); break;
      case "success": toast.success(text, options); break;
      case "custom": toast.custom(text); break;
      case "loading": toast.loading(text, options); break;
      case "promise": toast.promise(promise, {
        loading: 'Loading',
        success: (data) => `Successfully saved ${data.name}`,
        error: (err) => `This just happened: ${err.toString()}`,
      }, options); break;
      default: toast(text, options); break;
    }
  }

  const currencyOptions = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  };

  function deleteItem(item_id) {
    var cart = props?.cart;
    cart.attributes.opciones = cart.attributes.opciones.filter(i => i.item_id !== item_id);
    props.updateCart(cart, "delete");
  }

  props?.cart?.attributes?.opciones?.forEach(item => {
    const producto = props?.cart?.attributes?.productos?.data.find(p => p.id === item.producto_id);
    console.log("Producto", producto);
    var precio = producto?.attributes?.precio_base;
    var modificadores = [];
    item.modificadores.forEach(modificador => {
      modificadores.push(modificador.valor);
      precio += modificador.precio;
    });
    precio = precio * item.quantity;
    total += precio;
    const precio_formatted = new Intl.NumberFormat('en-US', currencyOptions).format(precio);
    _items.push(
        <div className="mb-1">
          <div className="rounded bg-white text-black">
            <div className="row mx-auto align-items-end">
              <div className="col-3">
                <img className="img-fluid" src={process.env.REACT_APP_CMS_URL + producto?.attributes?.galeria.data[0].attributes.url} alt="" />
              </div>
              <div className="col-6 pb-3">
                <p className="fw-bold">{producto?.attributes?.nombre} x {item?.quantity}</p>
                <small>{modificadores.join(" ")}</small>
              </div>
              <div className="col-3 pb-5">
                <button className="btn bg-transparent"><img src="assets/img/icons/trash.png" alt="" onClick={(e) => deleteItem(item.item_id)} /></button>
              </div>
            </div>
          </div>
          <div className="rounded bg-white text-black mt-1">
            <div className="row mx-auto">
              <div className="offset-2 col-8 text-end">
                <span className="fw-bold">{precio_formatted}</span>
              </div>
            </div>
          </div>
        </div>
    );
  });

  const total_formatted = new Intl.NumberFormat('en-US', currencyOptions).format(total);
  const total_all_formatted = new Intl.NumberFormat('en-US', currencyOptions).format(total+parseFloat(shippingPrice));

  function getAddresses(id) {
    axios.get(process.env.REACT_APP_CMS_URL + "/api/domicilios?filters[activo][$eq]=true&filters[owner][id][$eq]=" + id + "&populate=*")// 
      .then((response) => {
        setDomicilios(response?.data?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getUserData() {
    const jwt = localStorage.getItem("jwt");
    if (jwt) {
      axios.post(process.env.REACT_APP_API_URL + "/api/customer/get-user-details", { jwt })
        .then((response) => {
          if (!response.data.blocked) {
            getAddresses(response.data.id);
          }
          else {
            localStorage.setItem("jwt", "");
            window.location = "/";
          }
        })
        .catch((error) => {
          console.error(error)
        });
    }
  }

  function selectAddress(address) {
    setSelectedAddress(address.id);
    calculateShippingPrice(address.attributes);
  }

  function calculateShippingPrice(e){
    setOverlayVisible(true);
    const address ={
      "address_state": e.estado,
      "address_city": e.ciudad,
      "address_cp": e.cp,
      "address_street": e.calle,
      "address_num_ext": e.numero
    };
    setAddressInformation(e);
    const body = {
      address,
      weight: 5
    }
    axios.post(process.env.REACT_APP_API_URL + "/api/fedex/get-rate", body)
        .then((response) => {
          if(response.data){
            if(response.data.status === "success"){
              setShippingPrice(response.data.data.price);
              setOverlayVisible(false);
            }
            else setOverlayVisible(false);
          }
          else setOverlayVisible(false);
        })
        .catch((error) => {
          console.error(error)
          //notify("Error calculando precio de envío", "error");
          setShippingPrice(200 + Math.floor(Math.random() * 5) * 8);
          //(200 + Math.floor(Math.random() * 5) * 8)
          setOverlayVisible(false);
        });
  }

  function createPurchase(){
    const jwt = localStorage.getItem("jwt");
    var body ={
      address: addressInformation,
      cart: props?.cart?.id,
      total,
      shippingPrice
    }
    if(jwt) body["jwt"] = jwt;
    axios.post(process.env.REACT_APP_API_URL + "/api/purchase/create-purchase", body)
        .then((response) => {
          localStorage.setItem("compra", response?.data?.data?.attributes?.identificador);
          localStorage.removeItem("cart");
          window.location = "/pagar"
        })
        .catch((error) => {
          notify("Error", "error")
          console.error(error)
        });
  }

  let _domicilios = [];
  domicilios?.forEach(domicilio => {
    _domicilios.push(
      <div className="row border border-1 border-light text-start p-2 rounded mb-1 mx-auto">
        <div className='col-11'>
          <h5>{domicilio.attributes.descripcion}</h5>
          <p hidden={selectedAddress !== domicilio.id}>{domicilio.attributes.calle} {domicilio.attributes.numero}, {domicilio.attributes.colonia}, {domicilio.attributes.cp}, {domicilio.attributes.ciudad}, {domicilio.attributes.estado}, {domicilio.attributes.telefono}</p>
          <p hidden={selectedAddress !== domicilio.id}>{domicilio.attributes.nombre}</p>
        </div>
        <div className="col-1 align-text-bottom">
          <input type="radio" checked={selectedAddress === domicilio.id} onClick={(e) => selectAddress(domicilio)} className="form-check mx-auto" style={{"width": "20px"}}/>
        </div>
      </div>
    );
  });

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div className="bg-black text-white text-center">
      {isOverlayVisible && <Overlay />}
      <div className="container p-5">
        <h4 className="mb-5">Mi Carrito</h4>
      </div>
      <div className="container">
        <section>
          <div className="row mb-3 mx-auto">
            <div className="col-12 col-lg-6">
              <h5>Productos</h5>
              {_items}
            </div>
            <div className="col-12 col-lg-6">
              <h5>Envío</h5>
              {_domicilios}
              <div className="row mx-auto mt-3 border rounded border-1 border-light p-2">
                <div className="col-11 text-start" hidden={_domicilios.length === 0}>
                  Otra dirección
                </div>
                <div className="col-1" hidden={_domicilios.length === 0}>
                  <input type="radio" checked={selectedAddress === "other_address"} onClick={(e) => setSelectedAddress("other_address")} className="form-check mx-auto" style={{"width": "20px"}}/>
                </div>
                <div hidden={selectedAddress !== "other_address" && _domicilios.length > 0}>
                  <AddressForm submit={e => calculateShippingPrice(e)}/>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3 mx-auto">
            <div className="col-12 col-lg-6 offset-lg-3">
              <div className="rounded bg-white text-black mt-1 p-2">
                <div className="row mx-auto">
                  <div className="col-6">
                    <span>Productos</span><br />
                    <span>Envío</span><br />
                    <span className="fw-bold">Total</span><br />
                  </div>
                  <div className="col-6">
                    <span>{total_formatted}</span><br />
                    <span>${parseFloat(shippingPrice) > 0 ? shippingPrice : "0.00"}</span><br />
                    <span className="fw-bold">{total_all_formatted}</span><br />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3 mx-auto">
            <div className="col-12 col-lg-6 offset-lg-3">
              <button onClick={(e) => createPurchase()} disabled={parseFloat(shippingPrice) === 0 || total === 0} className="btn bg-white text-dark p-3 w-100 fw-bold">Continuar compra</button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Carrito;
