import { Link } from 'react-router-dom';

function Footer(props) {
    return (
      <footer className="bg-black border-top border-1 border-white mt-3">
        <div className="row mx-auto">
            <div className="col-lg-6 col-6 text-start px-5 pt-3 pb-3 d-none d-lg-block">
                <h5 className="text-white">Contacto:</h5>
                <p className="text-white d-none d-lg-block">
                  <img className='img-fluid me-2' src="assets/img/icons/correo.png" />info@cafeeuropa.com
                </p>
                <p className="text-white d-none d-lg-block">
                  <img className='img-fluid me-2' src="assets/img/icons/phone.png" />800 cafeeur (2 23 33 87)
                </p>
                <p className="text-white d-none d-lg-block">
                  <img className='img-fluid me-2' src="assets/img/icons/wa.png" />443 2 23 33 87
                </p>
            </div>
            <div className="col-lg-6 col-7 text-start ps-4 pt-3 pb-3 d-lg-none">
                <h5 className="text-white">Contacto:</h5>
                <small className="text-white d-lg-none">
                  <img className='img-fluid me-2' src="assets/img/icons/correo.png" />info@cafeeuropa.com
                </small><br className='d-lg-none'/>
                <small className="text-white d-lg-none">
                  <img className='img-fluid me-2' src="assets/img/icons/phone.png" />800 cafeeur (2 23 33 87)
                </small><br className='d-lg-none'/>
                <small className="text-white d-lg-none">
                  <img className='img-fluid me-2' src="assets/img/icons/wa.png" />443 2 23 33 87
                </small><br className='d-lg-none'/>
            </div>
            <div className="col-lg-6 col-6 text-end px-5 pt-3 pb-3 d-flex flex-column d-none d-lg-block">
                <Link to="/buzon" className="text-white mt-auto d-none d-lg-block">
                  <img className='img-fluid me-2' src="assets/img/icons/buzon.png" />Buzón
                </Link>
                <Link to="/facturacion" className="text-white d-none d-lg-block">
                  Facturación
                </Link>
            </div>
            <div className="col-lg-6 col-5 text-end pt-3 pb-3 d-flex flex-column d-lg-none">
                <Link to="/buzon" className="text-white mt-auto d-lg-none">
                  <img className='img-fluid me-2' src="assets/img/icons/buzon.png" /><small>Buzón</small>
                </Link>
                <Link to="/facturacion" className="text-white d-lg-none">
                  <small>Facturación</small>
                </Link>
            </div>
        </div>
      </footer>
    );
}
  
export default Footer;
  