function Terminos() {
    return (
      <div className="bg-black text-white text-center">
        <div className="container p-5">
          <h4 className="mb-5">Términos y Condiciones - Crear cuenta en Página Web</h4>
          <div className="row mx-auto text-start">
          POLÍTICA DE PRIVACIDAD Y POLÍTICA DE COOKIES DE CAFÉ EUROPA

            <p>Esta política de privacidad (Política) describe los datos personales recopilados o generados (tratados) cuando usted interactúa con la página web de Café Europa (la Página Web), las experiencias digitales, eventos de tiendas, o alguno de nuestros otros productos o servicios, todos los cuales forman parte de la Plataforma de Café Europa (la Plataforma). Esta Política describe los tipos de datos personales recopilados y cómo se utilizan, comparten y protegen. También explica las opciones que tiene en relación con sus datos personales y cómo puede ponerse en contacto con nosotros.</p>

            <p className="fw-bold">
            · <a href="#section_1" className="text-white" >¿Quién es el Responsable del Procesamiento de sus Datos Personales?</a><br/>
            · <a href="#section_2" className="text-white" >¿Qué Datos Personales Recolectamos y Cuándo?</a><br/>
            · <a href="#section_3" className="text-white" >Herramientas Para Administrar Los Datos Personales Que Recolectamos</a><br/>
            · <a href="#section_4" className="text-white" >¿Por Qué y Cómo Utilizamos (Tratamos) Sus Datos Personales?</a><br/>
            · <a href="#section_5" className="text-white" >Intercambio de sus Datos Personales</a><br/>
            · <a href="#section_6" className="text-white" >Cookies y Pixeles de Seguimiento</a><br/>
            {/*· <a href="#section_7" className="text-white" >Uso de la Plataforma Café Europa con Productos y Servicios de terceros</a><br/>*/}
            · <a href="#section_8" className="text-white" >Cambios a nuestra Política de Privacidad</a><br/>
            · <a href="#section_9" className="text-white" >Preguntas y Comentarios</a>
            </p>

            <h4 className="fw-bold mt-3" id="section_1">¿Quién es el Responsable Del Procesamiento De Sus Datos Personales?</h4>

            <p>Cafés Valladolid es responsable de las compras directas en línea con Café Europa.</p>

            <p>Para cualquier duda relacionada con tus derechos previstos en la Ley Federal de Protección al Consumidor puedes contactar directamente a Cafés Valladolid (Calle García Pueblita #104, Colonia Centro, Código Postal 58000, Morelia, Michoacán).</p>

            <p>En esta política de privacidad, los términos “Café Europa”, “nuestra”, “nuestros” o “nosotros” se refieren a la(s) sociedad(es) aplicable(s) de Café Europa.</p>

            <h4 className="fw-bold mt-3" id="section_2">¿Qué Datos Personales Recopilamos y Cuándo?</h4>

            <p>Para proporcionarle los productos o servicios que solicite, le pediremos ciertos datos personales. Por ejemplo, cuando realice compras, se ponga en contacto con nuestros servicios de atención al consumidor, solicite recibir comunicaciones, cree una cuenta, participe en nuestros eventos o concursos, o utilice nuestra página web. Adicionalmente, cuando usted solicite servicios cuenta. Estos datos personales incluyen sus:</p>

            <p>
            · Datos de contacto, incluyendo nombre, correo electrónico, número de teléfono y dirección de envío y facturación.<br/>
            · Información de acceso y cuenta, incluyendo nombre de usuario, contraseña e ID de usuario único. <br/>
            · Datos personales incluyendo sexo, ciudad natal, fecha de nacimiento e historial de compra.<br/>
            · Información de pago o tarjeta de crédito.<br/>
            · Preferencias personales incluyendo su lista de deseos, así como las preferencias de mercadotecnia y cookies.
            </p>

            <p>Al interactuar con nuestra Página Web, algunos datos se recopilan automáticamente y son compartidos con Café Europa a través de su dispositivo o navegador. En la sección de “Cookies y Pixeles de Seguimiento” se incluye más información sobre estas prácticas. Dichos datos incluyen:</p>

            <p>
            · Identificadores de dispositivo, estado de llamada, acceso a la red, información de almacenamiento e información de la batería<br/>
            · Cookies, direcciones IP, encabezados de referencia, datos que identifican su navegador web y versión, contadores de visita y etiquetas web, y etiquetas e interacciones con nuestra Página Web.
            </p>

            <h4 className="fw-bold mt-3" id="section_3">Herramientas para Administrar los Datos Personales Que Recolectamos</h4>

            <p>Al utilizar nuestra página web, también le informamos sobre nuestra recopilación y uso a través de permisos estándar en tiendas de apps, sistemas operativos móviles y en la experiencia de la página web. Cuando sea necesario también le pediremos su consentimiento.</p>

            <p>En muchos casos, su navegador web o plataforma de dispositivo móvil proporcionará herramientas adicionales que le permitirán controlar cuándo su dispositivo recopila o comparte determinadas categorías de información. Por ejemplo, su dispositivo móvil o navegador web puede ofrecerle herramientas que le permitan gestionar el uso de cookies o el uso compartido de ubicaciones. Le animamos a que se familiarice y utilice las herramientas disponibles en sus dispositivos.</p>

            <h4 className="fw-bold mt-3" id="section_4">¿Por Qué y Cómo utilizamos (tratamos) Sus Datos Personales?</h4>

            <p>Sus datos personales que recopilamos son utilizados de la siguiente manera:</p>

            <p>Para Proporcionar las Funciones de la Plataforma y Servicios Que Solicita</p>

            <p>Cuando utilice nuestra página web, utilizaremos sus datos para proporcionarle el producto o servicio que haya seleccionado. Por ejemplo, si ustes hace una compra en cafeeuropa.com.mx , o participa en un evento o promoción, utilizaremos la información de contacto que nos proporcione para comunicarnos con usted sobre la compra, evento o promoción. Si usted contacta con nuestros servicios de atención al consumidor, utilizaremos información sobre usted, tal como la información de entrega o pago, o el producto que ha comprado, con la finalidad de ayuadarle a resolver un problema o pregunta.</p>

            <p>Para Comunicar Información sobre nuestros Productos, Servicios, Eventos y para Otros Objetivos Promocionales</p>

            <p>Cuando brinde su consentimiento, le enviaremos comunicaciones publicitarias y noticias sobre los productos, servicios, eventos y otras promociones de Café Europa que puedan ser de su interés. Puede darse de baja en cualquier momento después de haber dado su consentimiento.</p>

            <p>Marketing directo</p>

            <p>Si usted es un cliente actual de Café Europa (por ejemplo, si ha realizado una orden con nosotros), podemos utilizar la dirección de correo electrónico proporcionada para enviarle comunicaciones de mercadotecnia sobre productos o servicios similares de Café Europa, salvo que haya renunciado a ello. En otros casos, le solicitaremos su consentimiento para enviarle información de mercadotecnia sobre los productos, servicios, eventos y promociones de Café Europa.</p>

            <p>Personalización</p>

            <p>Podemos utilizar la información que usted nos proporciona - tales como sus visitas o compras realizadas en la tienda de Café Europa - para personalizar las comunicaciones sobre productos y servicios que pueden ser de su interés. Al hacerlo, es posible que combinemos la información que usted nos comparta con información que nosotros generemos sobre su actividad en línea, incluyendo análisis y observaciones internas.</p>

            <p>Para Operar, Mejorar y Mantener nuestro Negocio, Productos y Servicios</p>

            <p>Es posible que utilicemos los datos que usted nos proporcione para operar nuestro negocio. Por ejemplo, cuando usted realiza una compra, podemos utilizar esa información para la contabilidad, auditoría y otras funciones internas. Es posible que utilicemos datos acerca de cómo utiliza nuestros productos y servicios para mejorar su experiencia de usuario y para ayudarnos a diagnosticar problemas técnicos y de servicio, así como para administrar nuestra página web.</p>

            <p>Para proteger Nuestra Propiedad o Seguridad o Derechos Propios o de Terceros</p>

            <p>También es posible que utilicemos datos acerca de cómo usted utiliza nuestra Página Web para prevenir, detectar o investigar fraudes, abusos, usos ilegales e infracciones de nuestros Términos de uso y para cumplir con las órdenes judiciales, solicitudes gubernamentales o leyes aplicables.</p>

            <p>Para Objetivos Generales de Investigación y Análisis</p>

            <p>Utilizamos datos acerca de cómo nuestros visitantes utilizan nuestra Página Web y servicios para comprender el comportamiento o las preferencias de los clientes. Por ejemplo, podemos utilizar la información acerca de cómo los visitantes de cafeeuropa.com.mx buscan y encuentran productos para comprender mejor las distintas formas de organizar y presentar ofertas de productos en nuestra tienda.</p>

            <p>Otras Finalidades</p>

            <p>También podemos utilizar sus datos personales para otras finalidades y, para ellos, le proporcionaremos un aviso específico en el momento de la recopilación y obtendremos su consentimiento cuando sea necesario.</p>

            <p>Fundamentos Legales</p>

            <p>Para procesar sus datos personales, nos basamos en ciertos fundamentos legales, según cómo interactúe con nuestra página web y las leyes aplicables.</p>

            <p>· Cuando adquiere productos de cafeeuropa.com.mx, necesitamos sus datos personales para cumplir nuestro contrato con usted. Por ejemplo, necesitamos información del pago y datos de contacto para entregar su pedido.</p>

            <h4 className="fw-bold mt-3" id="section_5">Intercambio de sus Datos Personales</h4>

            <p>Intercambio de Café Europa</p>

            <p>Café Europa comparte sus datos personales con:</p>

            <p>
            · Sociedades del grupo de Café Europa para las finalidades y bajo las condiciones descritas anteriormente<br/>
            · Proveedores de servicios que tratan datos personales en nombre de Café Europa, por ejemplo para tratar tarjetas de crédito y pagos, envíos y entregas, gestionar y dar servicio a nuestros datos, distribuir correos electrónicos, investigar y analizar, gestionar promociones de marcas y productos, así como administrar ciertos servicios y funciones. Cuando utilicemos los servicios de proveedores, celebraremos acuerdos con ellos requiriendo que estos implementen medidas técnicas y organizativas apropiadas para proteger sus datos personales.<br/>
            · Otros terceros en la medida necesaria para: (i) cumplir con una solicitud del gobierno, una orden judicial o ley aplicable; (ii) prevenir usos ilegales de nuestra página web o violaciones de los Términos de Uso y políticas de nuestra página web; (iii) defendernos contra reclamaciones de terceros; y (iv) ayudar en la prevención o investigación de fraude (por ejemplo, falsificación) servicios para hacer coincidir la información que nosotros tenemos con datos personales en bases de datos de esos proveedores para crear audiencias personalizadas y adaptar la publicidad a sus intereses en Internet, incluyendo redes sociales, según lo permita la ley aplicable. Puede optar por no recibir publicidad personalizada y excluirse de audiencias personalizadas utilizando la configuración relevante en nuestra página web.<br/>
            · A cualquier otro tercero cuando usted haya prestado consentimiento
            </p>

            <p>También podemos transferir los datos personales que tengamos sobre usted en caso de que vendamos o transfiramos la totalidad o una parte de nuestro negocio o activos (incluyendo en caso de reorganización, escisión, disolución o liquidación).</p>

            <p>Protección y Gestión de sus Datos Personales</p>

            <p>Encriptación y Seguridad</p>

            <p>Utilizamos una variedad de medidas de seguridad técnica y organizativa, incluyendo herramientas de encriptación y autenticación, para mantener la seguridad de sus datos personales.</p>

            <p>Conservación de sus Datos</p>

            <p>Su información personal se conservará durante el tiempo que sea necesario para llevar a cabo los fines establecidos en esta política de privacidad (a menos que la ley aplicable exija un período de conservación más prolongado). En términos generales, esto significa que conservaremos sus datos personales mientras mantenga su cuenta de Café Europa. Para los datos personales relacionados con las compras de productos, los conservamos por más tiempo para cumplir con obligaciones legales (como las leyes fiscales y de ventas y para fines de garantía). </p>

            <p>Sus Derechos Relacionados con sus Datos Personales</p>

            <p>Usted tiene derecho a solicitar: (i) el acceso a sus datos personales; (ii) una copia en formato electrónico de sus datos personales (portabilidad) y el envío de estos datos a otra compañía; (iii) la corrección de sus datos personales si están incompletos o son inexactos; o (iv) la eliminación o restricción de sus datos personales en ciertos casos contemplados por las leyes. Estos derechos no son absolutos. Cuando hayamos obtenido su consentimiento para el tratamiento de sus datos personales, usted tiene derecho a revocar su consentimiento en cualquier momento. Es posible que no pueda aprovechar al máximo nuestros servicios si no acepta el procesamiento de sus datos personales.</p>

            <p>Para solicitar una copia de sus datos personales o ejercer alguno de sus otros derechos, por favor enviando un correo electrónico a info@cafeeuropa.com.mx</p>

            <p>Optar por no participar en el marketing directo</p>

            <p>Si tiene una cuenta de Café Europa, puede optar por no recibir comunicaciones de marketing de Café Europa modificando sus preferencias en la sección “ver o cambiar mi perfil” de nuestra página web. También puede darse de baja modificando sus suscripciones de correo electrónico haciendo clic en el enlace para darse de baja o siguiendo las instrucciones de exclusión incluidas en el mensaje. Alternativamente, también puede comunicarse con nosotros utilizando los datos de contacto en la sección “Preguntas y comentarios” a continuación.</p>

            <h4 className="fw-bold mt-3" id="section_6">Cookies y Pixeles de Seguimiento</h4>

            <p>Café Europa recibe y registra información, que puede incluir datos personales, de su navegador cuando usted utiliza nuestra página web. Utilizamos una variedad de métodos, tales como cookies y pixeles de seguimiento (“Pixel Tags”) para recopilar esta información, que puede incluir su (i) dirección IP; (ii) identificador único de cookies, información de cookies e información sobre si su dispositivo tiene software para acceder a ciertas funciones; (iii) identificador único de dispositivo y tipo de dispositivo; (iv) dominio, tipo de navegador e idioma, (v) configuración del sistema operativo y del sistema; (vi) país y zona horaria; (vii) páginas web visitadas con anterioridad; (viii) información acerca de su interacción con nuestra página web, tales como el comportamiento al hacer clic, las compras y las preferencias indicadas; y (ix) tiempos de acceso y URL de referencia.</p>

            <p>Los terceros también pueden recopilar información a través de la página web mediante cookies, plugins y widgets de terceros. Estos terceros recogen datos directamente de su navegador web y el tratamiento de estos datos está sujeto a sus propias políticas de privacidad.</p>

            <p>Utilizamos cookies y pixeles de seguimiento para realizar un seguimiento del uso que hacen nuestros clientes de la página web y para comprender las preferencias de nuestros clientes (tales como las opciones de país e idioma). Esto nos permite ofrecer servicios a nuestros clientes y mejorar su experiencia online. También utilizamos cookies y pixeles de seguimiento para obtener datos agregados sobre el tráfico y la interacción de la página web, con la finalidad de identificar tendencias y obtener estadísticas para que podamos mejorar nuestra página web. Generalmente hay dos categorías de cookies utilizadas en nuestras páginas web:</p>

            <p>
            · Funcionales: Estas cookies son necesarias para el funcionamiento básico de la página web y, por lo tanto, siempre están habilitadas. Estas incluyen cookies que le permiten ser recordado mientras explora nuestra página web en una sola sesión o, si lo solicita, de sesión en sesión. Ayudan a hacer posible el proceso de compra y pago, así como a asistir cuestiones de seguridad y de conformidad con las normativas.<br/>
            · De rendimiento: Estas cookies nos permiten mejorar la funcionalidad de nuestra página web mediante el seguimiento del uso. En algunos casos, estas cookies mejorar la velocidad con la que podemos procesar su solicitud y nos permiten recordar las preferencias de la página web que usted ha seleccionado. Rechazar estas cookies puede generar recomendaciones mal adaptadas y un rendimiento lento de la página web.
            </p>

            <p>Para obtener un resumen completo y actualizado de cada tercero que acceda a su navegador web (a través de la página web o de otro modo), le recomendamos que instale un plugin de navegador web diseñado para este fin. También puede elegir que su ordenador le advierta cada vez que se envía una cookie, o puede elegir desactivar todas las cookies. Esto se realiza a través de la configuración del navegador en cada navegador y dispositivo que utilice. Cada navegador es un poco diferente, así que mire en el menú Ayuda de su navegador para saber la manera correcta de modificar sus cookies. Si desactiva las cookies, es posible que no tenga acceso a muchas funciones que hacen que nuestra página web sea más eficiente y por lo tanto, algunos de nuestros servicios no funcionarán correctamente.</p>

            <p>Del mismo modo, puede ajustar sus preferencias sobre publicidad en su dispositivo móvil a nivel de dispositivo. Por ejemplo, para ajustar sus preferencias sobre publicidad en iOS, visite Configuración, Privacidad, Publicidad, Limitar seguimiento de anuncios. Para ajustar sus preferencias sobre publicidad en Android, visite configuración, Google, Anuncios, Cancelar personalización de anuncios.</p>

            <h4 className="fw-bold mt-3" id="section_8">Cambios a nuestra Política de Privacidad</h4>

            <p>La ley aplicable y nuestras prácticas cambian con el tiempo. Si decidimos actualizar nuestra Política, publicaremos los cambios en nuestra página web. Si cambiamos materialmente la forma en que tratamos los datos personales, le proporcionaremos un aviso previo o, cuando sea legalmente necesario, solicitaremos su consentimiento antes de llevar a cabo dichos cambios. Le recomendamos encarecidamente que lea nuestra política y se mantenga informado de nuestras prácticas. Esta política fue modificada por última vez el 04 de JUNIO de 2024.</p>

            <h4 className="fw-bold mt-3" id="section_9">Preguntas y Comentarios</h4>

            <p>Damos la bienvenida a preguntas, comentarios e inquietudes sobre nuestra política y prácticas de privacidad. Si desea enviarnos sus comentarios, preguntas o inquietudes, o si desea ejercer sus derechos relacionados con sus datos personales, por favor enviando un correo electrónico a info@cafeeuropa.com.mx</p>


          </div>
        </div>
      </div>
    );
  }
  
  //uzgr nbmp zomr zaji
  
  export default Terminos;
  