import axios from "axios";
import React, { useEffect, useState } from "react";

function EmailList() {
    const [user, setUser] = useState({});

    function getUserData() {
        const jwt = localStorage.getItem("jwt");
        if (jwt) {
            axios.post(process.env.REACT_APP_API_URL + "/api/customer/get-user-details", { jwt })
                .then((response) => {
                    if (!response.data.blocked) {
                        setUser(response.data);
                    }
                    else {
                        localStorage.setItem("jwt", "");
                        window.location = "/";
                    }
                })
                .catch((error) => {
                    window.location = "/";
                });
        }
        else window.location = "/";
    }

    function download() {
        const jwt = localStorage.getItem("jwt");
        if (jwt) {
            axios.post(process.env.REACT_APP_API_URL + "/api/customer/get-emails", { jwt })
                .then((response) => {
                    const blob = new Blob([response.data], { type: 'text/plain' });
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = "emails.txt";
                    link.click();
                    URL.revokeObjectURL(link.href);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    useEffect(() => {
        getUserData();
    }, []);

    return (
        <div className="bg-black text-white text-center">
            <div className="container">
                {user.id !== 18 ? "You're not authorized to see this information" : <button onClick={(e) => download()} className="btn bg-black border-white text-white">Descargar listado de emails</button>}
            </div>
        </div>
    );
}

export default EmailList;
