function Hablemos() {
  return (
    <div className="bg-black text-white text-center">
      <div className="container p-5">
        <h4>Misión</h4>
        <p>Satisfacer a nuestros clientes a través de un trato amable, en un ambiente cálido; ofreciendo la más alta calidad en granos finos y barras de cafés preparados; haciendo de esta actividad una tradición que brinde al personal oportunidades de desarrollo y autorrealización.</p>
        <h4 className="mt-5">Visión</h4>
        <p>Consolidarnos como una empresa líder en el mercado cafetalero, trascendiendo las fronteras del idioma, la edad, el género, condición social, costumbres y cualquier otra que se presente, llevando la cultura del café a todo ser humano que quiera disfrutar de los momentos que componen nuestra vida, siendo así una pasión que te inspira.</p>
        <h4 className="mt-5">Cadena Productiva del Café</h4>

        <div id="carouselHablemos" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active text-center">
              <img src={"../assets/img/hablemos/cadena1"+(window.innerWidth > 576 ? "" : "_sm")+".png"} className={"d-block img-fluid mx-auto"} alt="..."/>
            </div>
            <div className="carousel-item text-center">
              <img src={"../assets/img/hablemos/cadena2"+(window.innerWidth > 576 ? "" : "_sm")+".png"} className={"d-block img-fluid mx-auto"} alt="..."/>
            </div>
            <div className="carousel-item text-center">
              <img src={"../assets/img/hablemos/cadena3"+(window.innerWidth > 576 ? "" : "_sm")+".png"} className={"d-block img-fluid mx-auto"} alt="..."/>
            </div>
            <div className="carousel-item active text-center">
              <img src={"../assets/img/hablemos/cadena4"+(window.innerWidth > 576 ? "" : "_sm")+".png"} className={"d-block img-fluid mx-auto"} alt="..."/>
            </div>
            <div className="carousel-item text-center">
              <img src={"../assets/img/hablemos/cadena5"+(window.innerWidth > 576 ? "" : "_sm")+".png"} className={"d-block img-fluid mx-auto"} alt="..."/>
            </div>
            <div className="carousel-item text-center">
              <img src={"../assets/img/hablemos/cadena6"+(window.innerWidth > 576 ? "" : "_sm")+".png"} className={"d-block img-fluid mx-auto"} alt="..."/>
            </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselHablemos" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselHablemos" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>

        <div id="carouselHablemos2" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active text-center">
              <img src={"../assets/img/hablemos/4 Ms 1"+(window.innerWidth > 576 ? "" : " sm")+".png"} className={"d-block mx-auto img-fluid"} alt="..."/>
            </div>
            <div className="carousel-item text-center">
              <img src={"../assets/img/hablemos/4 Ms 2"+(window.innerWidth > 576 ? "" : " sm")+".png"} className={"d-block mx-auto img-fluid"} alt="..."/>
            </div>
            <div className="carousel-item text-center">
              <img src={"../assets/img/hablemos/4 Ms 3"+(window.innerWidth > 576 ? "" : " sm")+".png"} className={"d-block mx-auto img-fluid"} alt="..."/>
            </div>
            <div className="carousel-item active text-center">
              <img src={"../assets/img/hablemos/4 Ms 4"+(window.innerWidth > 576 ? "" : " sm")+".png"} className={"d-block mx-auto img-fluid"} alt="..."/>
            </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselHablemos2" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselHablemos2" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Hablemos;
