import axios from "axios";
import React, { useEffect, useState } from "react";
import PaymentForm from "./PaymentForm/PaymentForm"

function Pagar(props) {
    const [purchase, setPurchase] = useState(null);
    const [cart, setCart] = useState(null);

    var card_payment_container = document.getElementById("card-payment-container");
    if(card_payment_container) card_payment_container.innerHTML = "";

    const currencyOptions = {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
    };

    useEffect(() => {
        if (!purchase) getPurchase();
    }, []);

    function getPurchase() {
        const purchaseId = localStorage.getItem("compra");
        if (purchaseId) {
            axios.get(process.env.REACT_APP_CMS_URL + '/api/compras?filters[identificador][$eq]=' + purchaseId + '&populate[carrito][populate][0]=productos').then((response) => {
                if (response?.data?.data[0]) {
                    setPurchase(response?.data?.data[0]);
                    axios.get(process.env.REACT_APP_CMS_URL + '/api/carritos?filters[identificador][$eq]=' + response?.data?.data[0]?.attributes?.carrito?.data?.attributes?.identificador + '&filters[active][$eq]=true&populate[productos][populate][0]=galeria').then((response2) => {
                        if (response2?.data?.data[0]) {
                            setCart(response2?.data?.data[0]);
                        }
                        else window.location = "/";
                    }).catch((error) => {
                        console.error(error);
                        window.location = "/";
                    });
                }
                else window.location = "/";
            }).catch((error) => {
                console.error(error);
                window.location = "/";
            });
        }
        else window.location = "/";
    }

    var _items = [];
    cart?.attributes?.opciones?.forEach(item => {
        const producto = cart?.attributes?.productos?.data.find(p => p.id === item.producto_id);
        var precio = producto?.attributes?.precio_base;
        var modificadores = [];
        item.modificadores.forEach(modificador => {
            modificadores.push(modificador.valor);
            precio += modificador.precio;
        });
        precio = precio * item.quantity;
        const precio_formatted = new Intl.NumberFormat('en-US', currencyOptions).format(precio);
        _items.push(
            <div className="mb-1">
                <div className="rounded bg-white text-black">
                    <div className="row mx-auto align-items-end">
                        <div className="col-3">
                            <img className="img-fluid" src={process.env.REACT_APP_CMS_URL + producto?.attributes?.galeria.data[0].attributes.url} alt="" />
                        </div>
                        <div className="col-6 pb-3">
                            <p className="fw-bold">{producto.attributes.nombre} x {item.quantity}</p>
                            <small>{modificadores.join(" ")}</small>
                        </div>
                    </div>
                </div>
                <div className="rounded bg-white text-black mt-1">
                    <div className="row mx-auto">
                        <div className="offset-2 col-8 text-end">
                            <span className="fw-bold">{precio_formatted}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    return (
        <div className="bg-black text-white text-center">
            <div className="container">
                <section>
                    <div className="row mb-3 mx-auto">
                        <div className="col-12 col-lg-6">
                            <h5>Productos</h5>
                            {_items}
                            <div className="rounded bg-white text-black mt-1 p-2">
                                <div className="row mx-auto">
                                    <div className="col-6">
                                        <span>Productos</span><br />
                                        <span>Envío</span><br />
                                        <span className="fw-bold">Total</span><br />
                                    </div>
                                    <div className="col-6">
                                        <span>${purchase?.attributes?.precio}</span><br />
                                        <span>${purchase?.attributes?.envio}</span><br />
                                        <span className="fw-bold">${(purchase?.attributes?.envio + purchase?.attributes?.precio)}</span><br />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <h5>Envío</h5>
                            <div className="border border-1 border-white rounded p-2">
                                <p className="text-start">{purchase?.attributes?.nombre}</p>
                                <p className="text-start">{purchase?.attributes?.domicilio}</p>
                                <p className="text-start">{purchase?.attributes?.telefono}</p>
                                <p className="text-end fw-bold">${purchase?.attributes?.envio}</p>
                            </div>
                            <div className="card-payment-container mt-3">
                                <PaymentForm purchase={purchase?.id} amount={(purchase?.attributes?.envio + purchase?.attributes?.precio)} description={cart?.id}/>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Pagar;
