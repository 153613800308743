import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Menu() {
  const [categorias, setCategorias] = useState([]);
  const [subcategorias, setSubcategorias] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [platillos, setPlatillos] = useState([]);

  function getPlatillos() {
    axios
      .get(process.env.REACT_APP_CMS_URL + "/api/platillos?populate=*&pagination[limit]=100")
      .then((response) => {
        setPlatillos(response?.data?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getGrupos() {
    axios
      .get(process.env.REACT_APP_CMS_URL + "/api/grupos?populate=*")
      .then((response) => {
        setGrupos(response?.data?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getSubcategorias() {
    axios
      .get(process.env.REACT_APP_CMS_URL + "/api/subcategorias?populate=*")
      .then((response) => {
        setSubcategorias(response?.data?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getCategorias() {
    axios
      .get(process.env.REACT_APP_CMS_URL + "/api/categorias?populate=*")
      .then((response) => {
        setCategorias(response?.data?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    getPlatillos();
    getGrupos();
    getSubcategorias();
    getCategorias();
  }, []);

  var _categorias = [];

  categorias?.forEach((categoria) => {
    var _subcategorias = [];
    subcategorias
      .filter((s) => s?.attributes?.categoria?.data?.id === categoria?.id)
      ?.forEach((subcategoria) => {
        var grupo = grupos.find(
          (g) => g?.attributes?.subcategoria?.data?.id === subcategoria?.id
        );
        var platillo = platillos.find(
          (p) => p?.attributes?.grupo?.data?.id === grupo?.id
        );
        _subcategorias.push(
          <div className="col-6 col-lg-4 p-3">
            <Link
              to={"/menu/" + subcategoria.id}
              className="text-decoration-none"
            >
              <img
                alt=""
                className="img-fluid rounded"
                src={
                  process.env.REACT_APP_CMS_URL +
                  platillo?.attributes.imagen.data.attributes.url
                }
              />
              <p className="text-white">{subcategoria.attributes.nombre}</p>
            </Link>
          </div>
        );
      });

    _categorias.push(
      <section>
        <div className="border border-white">{categoria.attributes.nombre}</div>
        <div className="row mx-auto">{_subcategorias}</div>
      </section>
    );
  });

  return (
    <div className="bg-black text-white text-center">
      <div className="container p-5">
        <h4 className="mb-5">Selecciona un Menú</h4>
        {_categorias}
      </div>
    </div>
  );
}

export default Menu;
