import { Link, useNavigate } from 'react-router-dom';

function Navbar(props) {
    const jwt = localStorage.getItem("jwt");

    const navigate = useNavigate();

    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary bg-black sticky-top">
            <div className="container-fluid">
                <Link className="navbar-brand d-none d-lg-block" to="/">
                    <img alt="Café Europa" src="/assets/img/Logo.png" />
                </Link>
                <div className='d-lg-none row mx-auto'>
                    <div className='col-4 col-md-2 text-start'>
                        <div className='row mx-auto'>
                            <div className="col-6">
                                <Link className="nav-link text-white" to={jwt && jwt.length > 0 ? "cuenta" : "sesion"}>
                                    <img alt="" src="/assets/img/icons/User.png" />
                                </Link>
                            </div>
                            <div className="col-6">
                                <Link className="nav-link text-white" to="carrito">
                                    {props?.cart?.attributes?.opciones?.length > 0 && <span class="position-absolute translate-middle badge rounded-pill bg-white text-black">
                                        {props?.cart?.attributes?.opciones?.length < 10 ? props?.cart?.attributes?.opciones?.length : "+9"}
                                    </span>}
                                    <img alt="" src="/assets/img/icons/Cart.png" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-4 col-md-8'>
                        <Link className="navbar-brand" to="/">
                            <img alt="Café Europa" src="/assets/img/Logo.png" />
                        </Link>
                    </div>
                    <div className='col-4 col-md-2 text-end'>
                        <button className="navbar-toggler" style={{ color: "#FFFFFF" }} type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon" style={{ backgroundImage: "url(\"data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E\")" }}></span>
                        </button>
                    </div>
                </div>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <div className="row mx-auto w-100">
                        <div className="col-12 col-lg p-3">
                            <Link className="nav-link text-white" data-bs-toggle="collapse" data-bs-target="#navbarNav" onClick={ (e) => navigate("/") } aria-current="page" to="/">Inicio</Link>
                        </div>
                        <div className="col-12 col-lg p-3">
                            <Link className="nav-link text-white" data-bs-toggle="collapse" data-bs-target="#navbarNav" onClick={ (e) => navigate("hablemos") } to="hablemos">Hablemos de Café</Link>
                        </div>
                        <div className="col-12 col-lg p-3">
                            <Link className="nav-link text-white" data-bs-toggle="collapse" data-bs-target="#navbarNav" onClick={ (e) => navigate("menu") } to="menu">Menú</Link>
                        </div>
                        <div className="col-12 col-lg p-3">
                            <Link className="nav-link text-white" data-bs-toggle="collapse" data-bs-target="#navbarNav" onClick={ (e) => navigate("sucursales") } to="sucursales">Sucursales</Link>
                        </div>
                        <div className="col-12 col-lg p-3">
                            <Link className="nav-link text-white" data-bs-toggle="collapse" data-bs-target="#navbarNav" onClick={ (e) => navigate("tienda") } to="tienda">Tienda en Línea</Link>
                        </div>
                        <div className="col-12 col-lg p-3">
                            <Link className="nav-link text-white" data-bs-toggle="collapse" data-bs-target="#navbarNav" onClick={ (e) => navigate("bolsa") } to="bolsa">Bolsa de Trabajo</Link>
                        </div>
                        <div className="col-12 col-lg p-3">
                            <Link className="nav-link text-white" data-bs-toggle="collapse" data-bs-target="#navbarNav" onClick={ (e) => navigate("distribuidora") } to="distribuidora">Distribuidora</Link>
                        </div>
                        <div className="col-12 col-lg p-3 d-none d-lg-block">
                            <div className="row mx-auto">
                                <div className="col-6">
                                    <Link className="nav-link text-white" to={jwt && jwt.length > 0 ? "cuenta" : "sesion"}>
                                        <img alt="" src="/assets/img/icons/User.png" />
                                    </Link>
                                </div>
                                <div className="col-6">
                                    <Link className="nav-link text-white" to="carrito">
                                        {props?.cart?.attributes?.opciones?.length > 0 && <span class="position-absolute translate-middle badge rounded-pill bg-white text-black">
                                            {props?.cart?.attributes?.opciones?.length < 10 ? props?.cart?.attributes?.opciones?.length : "+9"}
                                        </span>}
                                        <img alt="" src="/assets/img/icons/Cart.png" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
