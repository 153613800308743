import axios from "axios";
import React, { useEffect, useState } from "react";

function Sucursales() {
  const [sucursales, setSucursales] = useState([]);
  const [selectedSucursal, setSelectedSucursal] = useState(null);

  function getSucursales() {
    axios
      .get(process.env.REACT_APP_CMS_URL + "/api/sucursals?populate=*")
      .then((response) => {
        setSucursales(response?.data?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    getSucursales();
  }, []);

  var _sucursales = [];
  sucursales.forEach(sucursal => {
    _sucursales.push(
      <div key={"sucursal_" + sucursal.id} className="col-6 col-lg-4 p-2">
        <div className="sucursal-image rounded" style={{"cursor" : "pointer"}} onClick={(e) => setSelectedSucursal(sucursal)} data-bs-toggle="modal" data-bs-target="#sucursalesModal">
          <img alt="" className="img-fluid text-center rounded" src={process.env.REACT_APP_CMS_URL + sucursal?.attributes.galeria.data[0].attributes.url} />
          <div className="sucursal-info">
            <strong>{sucursal.attributes.nombre}</strong>
            <p>{sucursal.attributes.direccion}</p>
          </div>
        </div>
      </div>
    );
  })

  var _galeria = [];
  selectedSucursal?.attributes?.galeria?.data?.forEach((img, index) => {
    _galeria.push(
      <div className={"carousel-item p-5"+(index === 0 ? " active" : "")}>
        <img src={process.env.REACT_APP_CMS_URL + img?.attributes?.url} className="img-fluid" alt="..." />
      </div>
    );
  })

  return (
    <div className="bg-black text-white text-center">
      <div className="container p-5">
        <h4 className="mb-5">Nuestras sucursales</h4>
        <iframe id="iframe" title="map" src="https://www.google.com/maps/d/u/0/embed?mid=1BbsTHZ0SdW9X-Sg9IQy1-wFlFb-LOTMc" width="100%" height="450" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        <div className="row mx-auto">
          {_sucursales}
          <div className="modal fade" id="sucursalesModal" tabindex="-1" aria-labelledby="sucursalesModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content bg-transparent">
                <div className="modal-header">
                  <button type="button" className="btn-close btn-white text-white" data-bs-dismiss="modal" aria-label="Close">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                    </svg>
                  </button>
                </div>
                <div className="modal-body border border-1 border-white rounded bg-black">
                  <div id="carouselSucursal" className="carousel slide">
                    <p>{selectedSucursal?.attributes?.nombre}</p>
                    <div className=" mx-auto mx-auto">
                      <div className="col-4 offset-4 border border-1 border-white mb-4"></div>
                    </div>
                    <div className="carousel-inner">
                      {_galeria}
                    </div>
                    {_galeria.length > 1 && <div className="row mx-auto">
                      <div className="col-12 col-lg-4 offset-lg-4">
                        <button className="btn bg-transparent" type="button" data-bs-target="#carouselSucursal" data-bs-slide="prev">
                          <span className="carousel-control-prev-icon rounded rounded-circle border border-1 border-white" aria-hidden="true"></span>
                        </button>
                        <button className="btn bg-transparent" type="button" data-bs-target="#carouselSucursal" data-bs-slide="next">
                          <span className="carousel-control-next-icon rounded rounded-circle border border-1 border-white" aria-hidden="true"></span>
                        </button>
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sucursales;
