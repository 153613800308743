import { Link } from 'react-router-dom';
import axios from "axios";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
import AddressForm from '../AddressForm/AddressForm';

function Cuenta() {

  const [domicilios, setDomicilios] = useState([]);
  const [compras, setCompras] = useState([]);
  const [user, setUser] = useState({});

  function getAddresses(id) {
    axios.get(process.env.REACT_APP_CMS_URL + "/api/domicilios?filters[activo][$eq]=true&filters[owner][id][$eq]=" + id + "&populate=*")// 
      .then((response) => {
        setDomicilios(response?.data?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getPurchases(id) {
    axios.get(process.env.REACT_APP_CMS_URL + "/api/compras?filters[usuario][id][$eq]=" + id + "&populate=*")// 
      .then((response) => {
        setCompras(response?.data?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getUserData() {
    const jwt = localStorage.getItem("jwt");
    if (jwt) {
      axios.post(process.env.REACT_APP_API_URL + "/api/customer/get-user-details", { jwt })
        .then((response) => {
          if (!response.data.blocked) {
            setUser(response.data);
            getAddresses(response.data.id);
            getPurchases(response.data.id);
          }
          else {
            localStorage.setItem("jwt", "");
            window.location = "/";
          }
        })
        .catch((error) => {
          window.location = "/";
        });
    }
    else window.location = "/";
  }

  function createAddress(e) {
    const jwt = localStorage.getItem("jwt");
    if (jwt) {
      const body = { ...e, jwt };
      axios.post(process.env.REACT_APP_API_URL + "/api/address/create-address", body)
        .then((response) => {
          if (response.status == 200) {
            notify("Dirección guardada exitosamente", "success");
            this.getPurchases(user.id)
          }
        })
        .catch((error) => {
          notify("Error al intentar guardar la direccion", "error");
          console.error(error);
        });
    }
  }

  const notify = (text, type, promise) => {
    const options = {
      style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
      },
    }
    switch (type) {
      case "error": toast.error(text, options); break;
      case "success": toast.success(text, options); break;
      case "custom": toast.custom(text); break;
      case "loading": toast.loading(text, options); break;
      case "promise": toast.promise(promise, {
        loading: 'Loading',
        success: (data) => `Successfully saved ${data.name}`,
        error: (err) => `This just happened: ${err.toString()}`,
      }, options); break;
      default: toast(text, options); break;
    }
  }

  function deleteAddress(id) {
    const body = {
      id,
      jwt: localStorage.getItem("jwt")
    }
    axios.post(process.env.REACT_APP_API_URL + "/api/address/delete-address", body).then((response) => {
      if (response.status === 200) {
        notify("Domicilio eliminado");
        getAddresses();
      }
      else notify("Error: " + response.status, "error");
    }).catch((error) => {
      notify(error?.response?.data?.error, "error");
    });
  }

  function signOut() {
    localStorage.removeItem("jwt");
    window.location = "/";
  }

  useEffect(() => {
    getUserData();
  }, []);

  let _domicilios = [];
  domicilios?.forEach(domicilio => {
    _domicilios.push(<div className='border border-1 border-light bg-white text-black text-start p-2 rounded mb-1'>
      <div className='row mx-auto'>
        <div className='col-10'>
          <h5>{domicilio.attributes.nombre}</h5>
          <p>{domicilio.attributes.calle} {domicilio.attributes.numero}, {domicilio.attributes.colonia}, {domicilio.attributes.cp}, {domicilio.attributes.ciudad}, {domicilio.attributes.estado}, {domicilio.attributes.telefono}</p>
          <p>{domicilio.attributes.descripcion}</p>
        </div>
        <div className='col-2'>
          <button className='btn bg-transparent' onClick={(e) => deleteAddress(domicilio.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" className="bi bi-trash" viewBox="0 0 16 16">
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
            </svg>
          </button>
        </div>
      </div>
    </div>);
  });

  let _compras = [];
  compras?.forEach(compra => {
    _compras.push(
      <Link className='text-decoration-none' to={"/compra/" + compra?.attributes?.identificador}>
        <div className='border border-1 border-light text-start bg-white p-2 rounded mb-1 text-black'>
          <div className='row mx-auto'>
            <div className='col-12'>
              <h5>{compra.attributes.fecha}</h5>
              <p>{compra.attributes.estado.charAt(0).toUpperCase()+compra.attributes.estado.substring(1)}</p>
              <p>${(compra.attributes.envio + compra.attributes.precio)}</p>
              <p>{compra.attributes.nombre} ({compra.attributes.telefono})</p>
            </div>
          </div>
        </div>
      </Link>
    );
  });

  return (
    <div className="bg-black text-white text-center">
      <div className="container">
        <Toaster />
        <div className="row mx-auto">
          <div className="col-12 col-lg-6 order-2 order-lg-1 my-3">
            <h4 className="mb-3">Mi cuenta</h4>
            <div className="form-group mb-3">
              <label className="mb-1">Correo Electrónico</label>
              <div className="w-100 rounded p-2 border border-white mb-3 py-3">
                {user?.email}
              </div>
            </div>
            <div className="form-group mb-3">
              <label className="mb-1">Nombre</label>
              <div className="w-100 rounded p-2 border border-white mb-3 py-3">
                {user?.nombre} {user?.apellido}
              </div>
            </div>
            <Link to="cambio-contraseña" className="btn bg-white p-2 w-100 mb-2 py-3">Cambiar contraseña</Link>
            <div className='text-end'>
              <button onClick={e => signOut()} className="btn bg-white p-2 w-100 d-none d-lg-block py-3">Cerrar sesión</button>
              <button onClick={e => signOut()} className="btn bg-white p-2 w-50 d-lg-none py-3">Cerrar sesión</button>
            </div>
          </div>
          <div className="col-12 col-lg-6 order-1 order-lg-2 my-3">
            <div className="accordion" id="accordionAddress">
            <h4 className="mb-3">&nbsp;</h4>
              {_compras.length > 0 && <label className="mb-1">Mis pedidos</label>}
              {_compras.length > 0 && <div>
                <p className="text-black p-2 bg-white rounded fw-semibold py-3" style={{ "cursor": "pointer" }} data-bs-toggle="collapse" data-bs-target="#collapsePurchases" aria-expanded="false" aria-controls="collapsePurchases">
                  <span>Ver Detalles</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FFFFFF" className="bi bi-caret-down" viewBox="0 0 16 16">
                    <path d="M3.204 5h9.592L8 10.481zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659" />
                  </svg>
                </p>
                <div id="collapsePurchases" className="accordion-collapse collapse" data-bs-parent="#accordionAddress">
                  <div className="accordion-body" style={{ "maxHeight": "500px", "overflowY": "scroll", "scrollbarWidth": "none", "-ms-overflow-style": "none" }}>
                    {_compras}
                  </div>
                </div>
              </div>}
              <label className="mb-1">Mis domicilios</label>
              <div>
                <p className="text-black p-2 bg-white rounded fw-semibold py-3" style={{ "cursor": "pointer" }} data-bs-toggle="collapse" data-bs-target="#collapseList" aria-expanded="false" aria-controls="collapseList">
                  <span>Ver Detalles</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FFFFFF" className="bi bi-caret-down" viewBox="0 0 16 16">
                    <path d="M3.204 5h9.592L8 10.481zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659" />
                  </svg>
                </p>
                <div id="collapseList" className="accordion-collapse collapse" data-bs-parent="#accordionAddress">
                  <div className="accordion-body bg-black" style={{ "maxHeight": "500px", "overflowY": "scroll", "scrollbarWidth": "none", "-ms-overflow-style": "none" }}>
                    {_domicilios}
                  </div>
                </div>
              </div>
              <div className="border border-light rounded">
                <h5 className="bg-transparent text-white border-light" style={{ "cursor": "pointer" }} data-bs-toggle="collapse" data-bs-target="#collapseForm" aria-expanded="true" aria-controls="collapseForm">
                  <div className='row mx-auto'>
                    <div className='col-2'>
                      <svg className='me-5' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                      </svg>
                    </div>
                    <div className='col-10 text-start'>
                      <small>Agregar domicilio</small>
                    </div>
                  </div>
                </h5>
                <div id="collapseForm" className="accordion-collapse collapse" data-bs-parent="#accordionAddress">
                  <div className="accordion-body">
                    <AddressForm submit={(e) => createAddress(e)} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cuenta;
