import React, { useEffect, useState } from "react";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';

function AddressForm(props) {
    const [nombre, setNombre] = useState("");
    const [descripcion, setDescripcion] = useState("");
    const [telefono, setTelefono] = useState("");
    const [estado, setEstado] = useState("");
    const [ciudad, setCiudad] = useState("");
    const [cp, setCP] = useState("");
    const [colonia, setColonia] = useState("");
    const [calle, setCalle] = useState("");
    const [numero, setNumero] = useState("");
    const [numero_interior, setNumeroInterior] = useState("");
    const [entre_calle_1, setCalle1] = useState("");
    const [entre_calle_2, setCalle2] = useState("");
    const [estados, setEstados] = useState([]);
    const [ciudades, setCiudades] = useState([]);
    const [cps, setCps] = useState([]);
    const [colonias, setColonias] = useState([]);

    function submit(e) {
        e.preventDefault();

        if(
            nombre == "" ||
            telefono == "" ||
            telefono.length < 10 ||
            estado == "" ||
            ciudad == "" ||
            cp == "" ||
            colonia == "" ||
            calle == "" ||
            numero == ""
        ) notify("Información incompleta", "error");
        else{
            props.submit({
                nombre,
                descripcion,
                telefono,
                estado,
                ciudad,
                cp,
                colonia,
                calle,
                numero,
                numero_interior,
                entre_calle_1,
                entre_calle_2
            })
            if(window.location.pathname !== "/carrito"){
                setNombre("");
                setDescripcion("");
                setTelefono("");
                setEstado("");
                setCiudad("");
                setCP("");
                setColonia("");
                setCalle("");
                setNumero("");
                setNumeroInterior("");
                setCalle1("");
                setCalle2("");
            }
        }

        
    }

    const notify = (text, type, promise ) => {
        const options = {
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }
        switch(type){
          case "error": toast.error(text, options); break;
          case "success": toast.success(text, options); break;
          case "custom": toast.custom(text); break;
          case "loading": toast.loading(text, options); break;
          case "promise": toast.promise(promise, {
            loading: 'Loading',
            success: (data) => `Successfully saved ${data.name}`,
            error: (err) => `This just happened: ${err.toString()}`,
          }, options); break;
          default: toast(text, options); break;
        }
    }

    useEffect(() => {
        axios.get(`https://api.copomex.com/query/get_estados?token=${process.env.REACT_APP_SEPOMEX_TOKEN}`).then((response) => {
            setEstados(response.data.response.estado);
        })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    function handleChange(e, type) {
        switch (type) {
            case "estado":
                setEstado(e.target.value);
                axios.get(`https://api.copomex.com/query/get_municipio_por_estado/` + e.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + "?token=" + process.env.REACT_APP_SEPOMEX_TOKEN)
                    .then((response) => {
                        setCiudades(response.data.response.municipios);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                break;
            case "ciudad":
                setCiudad(e.target.value);
                axios.get(`https://api.copomex.com/query/get_cp_por_municipio/` + e.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + "?token=" + process.env.REACT_APP_SEPOMEX_TOKEN)
                    .then((response) => {
                        setCps(response.data.response.cp);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                break;
            case "cp":
                setCP(e.target.value);
                axios.get(`https://api.copomex.com/query/get_colonia_por_cp/` + e.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + "?token=" + process.env.REACT_APP_SEPOMEX_TOKEN)
                    .then((response) => {
                        setColonias(response.data.response.colonia);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                break;
            case "colonia":
                setColonia(e.target.value);
                break;
        }
    }

    var _estados = [];
    estados.forEach((est, index) => {
        _estados.push(<option value={est} key={"estado_" + index}>{est}</option>);
    });

    var _ciudades = [];
    ciudades.forEach((ciu, index) => {
        _ciudades.push(<option value={ciu} key={"ciudad_" + index}>{ciu}</option>);
    });

    var _cps = [];
    cps.forEach((c, index) => {
        _cps.push(<option value={c} key={"cp_" + index}>{c}</option>);
    });

    var _colonias = [];
    colonias.forEach((col, index) => {
        _colonias.push(<option value={col} key={"colonia_" + index}>{col}</option>);
    });

    return (
        <form>
            <div className="form-group mb-2">
                <label>Los elementos marcados con <span className="text-danger">*</span> son obligatorios</label>
            </div>
            <div className="form-group mb-2">
                <label>Nombre completo <span className="text-danger">*</span></label>
                <input value={nombre} onChange={(e) => setNombre(e.target.value)} type="text" className="w-100 rounded form-control p-2" />
            </div>
            <div className="form-group mb-2">
                <label>Descripción</label>
                <input value={descripcion} onChange={(e) => setDescripcion(e.target.value)} type="text" className="w-100 rounded form-control p-2" />
            </div>
            <div className="form-group mb-2">
                <label>Teléfono <span className="text-danger">*</span></label>
                <input value={telefono} onChange={(e) => {
                    if(isNaN(e.target.value.charAt(e.target.value.length-1))) e.target.value = e.target.value.substring(0, e.target.value.length - 1);
                    if(e.target.value.length > 10) e.target.value = e.target.value.substring(0, 10);
                    setTelefono(e.target.value);
                }} type="text" className="w-100 rounded form-control p-2" />
            </div>
            <div className="form-group mb-2">
                <label>Estado <span className="text-danger">*</span></label>
                <select value={estado} disabled={_estados.length === 0} onChange={(e) => handleChange(e, "estado")} className="w-100 rounded form-control p-2">
                    <option value="" disabled selected>Selecciona un estado</option>
                    {_estados}
                </select>
            </div>
            <div className="form-group mb-2">
                <label>Ciudad <span className="text-danger">*</span></label>
                <select value={ciudad} disabled={_ciudades.length === 0} onChange={(e) => handleChange(e, "ciudad")} className="w-100 rounded form-control p-2">
                    <option value="" disabled selected>Selecciona una ciudad</option>
                    {_ciudades}
                </select>
            </div>
            <div className="form-group mb-2">
                <label>Código postal <span className="text-danger">*</span></label>
                <select value={cp} disabled={_cps.length === 0} onChange={(e) => handleChange(e, "cp")} className="w-100 rounded form-control p-2">
                    <option value="" disabled selected>Selecciona un código postal</option>
                    {_cps}
                </select>
            </div>
            <div className="form-group mb-2">
                <label>Colonia <span className="text-danger">*</span></label>
                <select value={colonia} disabled={_colonias.length === 0} onChange={(e) => handleChange(e, "colonia")} className="w-100 rounded form-control p-2">
                    <option value="" disabled selected>Selecciona una colonia</option>
                    {_colonias}
                </select>
            </div>
            <div className="row mx-auto w-100">
                <div className="col-lg-6 px-2">
                    <div className="form-group mb-2">
                        <label>Calle <span className="text-danger">*</span></label>
                        <input value={calle} onChange={(e) => setCalle(e.target.value)} type="text" className="w-100 rounded form-control p-2" />
                    </div>
                </div>
                <div className="col-lg-3 px-2">
                    <div className="form-group mb-2">
                        <label>Número <span className="text-danger">*</span></label>
                        <input value={numero} onChange={(e) => setNumero(e.target.value)} type="number" className="w-100 rounded form-control p-2" />
                    </div>
                </div>
                <div className="col-lg-3 px-2">
                    <div className="form-group mb-2">
                        <label>Número int.</label>
                        <input value={numero_interior} onChange={(e) => setNumeroInterior(e.target.value)} type="number" className="w-100 rounded form-control p-2" />
                    </div>
                </div>
            </div>
            <div className="row mx-auto w-100">
                <div className="col-lg-6 px-2">
                    <div className="form-group mb-2">
                        <label>Entre calle</label>
                        <input value={entre_calle_1} onChange={(e) => setCalle1(e.target.value)} type="text" className="w-100 rounded form-control p-2" />
                    </div>
                </div>
                <div className="col-lg-6 px-2">
                    <div className="form-group mb-2">
                        <label>Y calle</label>
                        <input value={entre_calle_2} onChange={(e) => setCalle2(e.target.value)} type="text" className="w-100 rounded form-control p-2" />
                    </div>
                </div>
            </div>
            <button onClick={(e) => submit(e)} className="btn bg-white p-2 w-100 mt-4">Guardar</button>
        </form>
    );
}

export default AddressForm;