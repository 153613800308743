import { Link } from 'react-router-dom';
import axios from "axios";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';

function PasswordChange() {

  const [user, setUser] = useState({});
  const [actualPassword, setActualPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  function getUserData(){
    const jwt = localStorage.getItem("jwt");
    if (jwt){
      axios
        .post(process.env.REACT_APP_API_URL + "/api/customer/get-user-details", { jwt })
        .then((response) => {
          if (!response.data.blocked){
            setUser(response.data);
          }
          else{
            localStorage.setItem("jwt", "");
            window.location = "/";
          }
        })
        .catch((error) => {
          window.location = "/";
        });
    }
    else window.location = "/";
  }

  function handleSubmit(){
    const jwt = localStorage.getItem("jwt");
    if(newPassword === confirmPassword){
      axios.post(process.env.REACT_APP_API_URL + "/api/customer/password-change", {
        jwt,
        "currentPassword": actualPassword,
        "password": newPassword,
        "passwordConfirmation": confirmPassword
      })
      .then((response) => {
        notify("Tu contraseña ha sido modificada exitosamente", "success");
        setTimeout(() => {
          window.location = "/cuenta";
        }, 3000);
      })
      .catch((error) => {
        console.error(error);
        notify(error?.response?.data?.error, "error");
      });
    }
    else notify("Las contraseñas no coinciden.", "error");
  }
  

  const notify = (text, type, promise ) => {
    const options = {
      style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
      },
    }
    switch(type){
      case "error": toast.error(text, options); break;
      case "success": toast.success(text, options); break;
      case "custom": toast.custom(text); break;
      case "loading": toast.loading(text, options); break;
      case "promise": toast.promise(promise, {
        loading: 'Loading',
        success: (data) => `Successfully saved ${data.name}`,
        error: (err) => `This just happened: ${err.toString()}`,
      }, options); break;
      default: toast(text, options); break;
    }
  }

  useEffect(() => getUserData(), []);

  return (
    <div className="bg-black text-white text-center">
      <div className="container">
        <div className="row mx-auto align-items-end">
          <div className="col-12 col-lg-6">
            <h4 className="mb-3">Cambiar Contraseña</h4>
            <Toaster />
            <div className="form-group mb-3">
              <label className="mb-1">Correo Electrónico</label>
              <input type="text" className="w-100 rounded form-control p-2" value={user.email} disabled/>
            </div>
            <div className="form-group mb-3">
              <label className="mb-1">Contraseña actual</label>
              <input type="password" onChange={(e) => setActualPassword(e.target.value)} className="w-100 rounded form-control p-2 py-3"/>
            </div>
            <div className="form-group mb-3">
              <label className="mb-1">Contraseña nueva</label>
              <input type="password" onChange={(e) => setNewPassword(e.target.value)} className="w-100 rounded form-control p-2 py-3"/>
            </div>
            <div className="form-group mb-3">
              <label className="mb-1">Confirmar contraseña</label>
              <input type="password" onChange={(e) => setConfirmPassword(e.target.value)} className="w-100 rounded form-control p-2 py-3"/>
            </div>
          </div>
          <div className="col-12 col-lg-6 mt-2">
            <div className="row mx-auto">
              <div className="col-12 col-lg-6 offset-lg-3">
                <button onClick={(e) => handleSubmit()} className="btn bg-white py-3 w-100 mb-3">Cambiar Contraseña</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordChange;
