import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function Submenu() {
  const { id } = useParams();

  const [categorias, setCategorias] = useState([]);
  const [subcategorias, setSubcategorias] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [platillos, setPlatillos] = useState([]);

  function getPlatillos() {
    axios
      .get(process.env.REACT_APP_CMS_URL + "/api/platillos?populate=*")
      .then((response) => {
        setPlatillos(response?.data?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getGrupos() {
    axios
      .get(process.env.REACT_APP_CMS_URL + "/api/grupos?populate=*")
      .then((response) => {
        setGrupos(response?.data?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getSubcategorias() {
    axios
      .get(process.env.REACT_APP_CMS_URL + "/api/subcategorias?populate=*")
      .then((response) => {
        setSubcategorias(response?.data?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getCategorias() {
    axios
      .get(process.env.REACT_APP_CMS_URL + "/api/categorias?populate=*")
      .then((response) => {
        setCategorias(response?.data?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    getPlatillos();
    getGrupos();
    getSubcategorias();
    getCategorias();
  }, []);

  let _opciones = [];
  categorias.forEach((categoria) => {
    _opciones.push(
      <option key={categoria.id} value={categoria.id} disabled>
        {categoria.attributes.nombre}
      </option>
    );
    subcategorias
      .filter((s) => s.attributes.categoria.data.id === categoria?.id)
      ?.forEach((subcategoria) => {
        _opciones.push(
          <option key={"option_" + subcategoria.id} value={subcategoria.id}>
            {subcategoria.attributes.nombre}
          </option>
        );
      });
  });

  let _grupos = [];
  grupos
    ?.filter((g) => g.attributes.subcategoria.data.id === parseInt(id))
    ?.forEach((grupo) => {
      let _platillos = [];
      platillos
        ?.filter((p) => p.attributes.grupo.data.id === grupo.id)
        ?.forEach((platillo) => {
          _platillos.push(
            <div className="col-6 col-lg-4 p-3">
              <img
                alt=""
                className="img-fluid rounded"
                src={
                  process.env.REACT_APP_CMS_URL +
                  platillo?.attributes.imagen.data.attributes.url
                }
              />
              <p>{platillo.attributes.nombre}</p>
            </div>
          );
        });
      _grupos.push(
        <section key={"section_" + grupo.id}>
          <div className="border border-white">{grupo.attributes.nombre}</div>
          <div className="row mx-auto">{_platillos}</div>
        </section>
      );
    });

  return (
    <div className="bg-black text-white text-center">
      <div className="container p-5">
        <select
          className="border border-white bg-white w-100 p-2 text-center mb-3 rounded"
          onChange={(e) => (window.location = "/menu/" + e.target.value)}
        >
          <option selected disabled>
            Selecciona un Menú
          </option>
          {_opciones}
        </select>
        {_grupos}
      </div>
    </div>
  );
}

export default Submenu;
