import axios from "axios";
import React, { useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';

function Registro() {
  
  let [nombre, setNombre] = useState("");
  let [apellido, setApellido] = useState("");
  let [correo, setCorreo] = useState("");
  let [telefono, setTelefono] = useState("");
  let [password, setPassword] = useState("");
  let [passwordRepeat, setPasswordRepeat] = useState("");
  let [accept, setAccept] = useState(false);

  function handleSubmit(){
    var valid = validarFormulario()
    if (valid === true){
      axios
        .post(process.env.REACT_APP_API_URL + "/api/customer/register", {
          "email": correo,
          "name": nombre,
          "lastName": apellido,
          "phone": telefono,
          "password": password
        })
        .then((response) => {
          notify("Tu cuenta ha sido creada exitosamente", "success");
          localStorage.setItem("jwt", response.data.jwt);
          setTimeout(() => {
            window.location = "/cuenta";
          }, 3000);
        })
        .catch((error) => {
          notify(error?.response?.data?.error, "error");
        });
    }
    else notify(valid, "error");
  }

  function validarFormulario() {
    var valid = true;
    var errores = [];
    if(nombre === ""){
      valid = false;
      errores.push("El nombre es requerido");
    }
    if(apellido === ""){
      valid = false;
      errores.push("El apellido es requerido");
    }
    if(correo === ""){
      valid = false;
      errores.push("El correo electrónico es requerido");
    }
    else{
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if(!regex.test(correo)){
        valid = false;
        errores.push("El correo electrónico no es válido");
      }
    }
    if(telefono === ""){
      valid = false;
      errores.push("El teléfono es requerido");
    }
    if(telefono.length < 10){
      valid = false;
      errores.push("El teléfono está incompleto");
    }
    if(password !== passwordRepeat){
      valid = false;
      errores.push("Las contraseñas no coinciden");
    }
    if(!accept){
      valid = false;
      errores.push("Debes aceptar los términos y condiciones");
    }

    if(valid) return valid;
    else return errores.join("\n");
  }

  const notify = (text, type, promise ) => {
    const options = {
      style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
      },
    }
    switch(type){
      case "error": toast.error(text, options); break;
      case "success": toast.success(text, options); break;
      case "custom": toast.custom(text); break;
      case "loading": toast.loading(text, options); break;
      case "promise": toast.promise(promise, {
        loading: 'Loading',
        success: (data) => `Successfully saved ${data.name}`,
        error: (err) => `This just happened: ${err.toString()}`,
      }, options); break;
      default: toast(text, options); break;
    }
  }
  
  return (
    <div className="bg-black text-white text-center">
      <div className="container">
        <div className="row mx-auto align-items-end">
          <div className="col-12 col-lg-6 offset-lg-3">
            <h4 className="mb-3">Regístrate</h4>
            <Toaster />
            <div className="form-group mb-3">
              <label className="mb-1">Nombre</label>
              <input onChange={(e) => setNombre(e.target.value)} type="text" className="w-100 rounded form-control p-2"/>
            </div>
            <div className="form-group mb-3">
              <label className="mb-1">Apellido</label>
              <input onChange={(e) => setApellido(e.target.value)} type="text" className="w-100 rounded form-control p-2"/>
            </div>
            <div className="form-group mb-3">
              <label className="mb-1">Correo Electrónico</label>
              <input onChange={(e) => setCorreo(e.target.value)} type="text" className="w-100 rounded form-control p-2"/>
            </div>
            <div className="form-group mb-3">
              <label className="mb-1">Teléfono</label>
              <input onChange={(e) => {
                if(isNaN(e.target.value.charAt(e.target.value.length-1))) e.target.value = e.target.value.substring(0, e.target.value.length - 1);
                if(e.target.value.length > 10) e.target.value = e.target.value.substring(0, 10);
                setTelefono(e.target.value);
              }} type="text" className="w-100 rounded form-control p-2"/>
            </div>
            <div className="form-group mb-3">
              <label className="mb-1">Contraseña</label>
              <input onChange={(e) => setPassword(e.target.value)} type="password" className="w-100 rounded form-control p-2"/>
            </div>
            <div className="form-group mb-3">
              <label className="mb-1">Confirmar contraseña</label>
              <input onChange={(e) => setPasswordRepeat(e.target.value)} type="password" className="w-100 rounded form-control p-2"/>
            </div>
            <div className="form-group mb-3">
              <input onChange={(e) => setAccept(e.target.checked)} type="checkbox" className="form-check-input"/>
              <label className="form-check-label ms-2">He leído y acepto los <Link to="/terminos-y-condiciones">términos y condiciones.</Link></label>
            </div>
            <button onClick={(e) => handleSubmit()} className="btn bg-white p-2 w-100">Regístrate</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Registro;
