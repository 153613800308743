import { Link } from 'react-router-dom';
import axios from "axios";
import React, { useState } from "react";
import toast, { Toaster } from 'react-hot-toast';

function Sesion() {

  const jwt = localStorage.getItem("jwt");

  if(jwt && jwt.length > 0) window.location = "/cuenta";

  let [correo, setCorreo] = useState("");
  let [password, setPassword] = useState("");

  function handleSubmit() {
    axios
      .post(process.env.REACT_APP_API_URL + "/api/customer/login", {
        "email": correo,
        "password": password
      })
      .then((response) => {
        localStorage.setItem("jwt", response.data.jwt);
        setTimeout(() => {
          window.location = "/cuenta";
        }, 3000);
      })
      .catch((error) => {
        notify("Usuario o contraseña incorrectos", "error");
      });
  }

  const notify = (text, type, promise ) => {
    const options = {
      style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
      },
    }
    switch(type){
      case "error": toast.error(text, options); break;
      case "success": toast.success(text, options); break;
      case "custom": toast.custom(text); break;
      case "loading": toast.loading(text, options); break;
      case "promise": toast.promise(promise, {
        loading: 'Loading',
        success: (data) => `Successfully saved ${data.name}`,
        error: (err) => `This just happened: ${err.toString()}`,
      }, options); break;
      default: toast(text, options); break;
    }
  }

  return (
    <div className="bg-black text-white text-center">
      <div className="container">
        <div className="row mx-auto align-items-end">
          <div className="col-12 col-lg-6">
            <h4 className="mb-3">Inicia Sesión</h4>
            <Toaster />
            <div className="form-group mb-3">
              <label className="mb-1">Correo Electrónico</label>
              <input onChange={(e) => setCorreo(e.target.value)} type="text" className="w-100 rounded form-control p-2" />
            </div>
            <div className="form-group mb-3">
              <label className="mb-1">Contraseña</label>
              <input onChange={(e) => setPassword(e.target.value)} type="password" className="w-100 rounded form-control p-2" />
            </div>
            <button onClick={(e) => handleSubmit()} className="btn bg-white p-2 w-100">Iniciar Sesión</button>
          </div>
          <div className="col-12 col-lg-6">
            <div className="row mx-auto pt-4">
              <div className="col-6 fw-semibold">Aún no tienes una cuenta?</div>
              <div className="col-6">
                <Link to="/registro" className="btn bg-white py-3 fw-semibold">Regístrate aquí</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sesion;
