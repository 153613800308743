import axios from "axios";
import React, { useEffect, useState } from "react";

function Buzon() {
  const [message, setMessage] = useState("");
  const [emailAddress, setEmail] = useState("");
  
  function sendMessage() {
    axios.post(process.env.REACT_APP_API_URL + "/api/purchase/send-message", { emailAddress, message }).then((response) => {
      console.log("Response", response);
    }).catch((error) => {
      console.error(error);
    });
  }

  return (
    <div className="bg-black text-white text-center">
      <div className="container">
        <h4 className="mb-5">Buzón</h4>
        <hr/>
        <input onChange={(e) => setEmail(e.target.value)} type="text" placeholder={"Correo electrónico"} className="form-control w-100 rounded mb-3"/>
        <textarea onChange={(e) => setMessage(e.target.value)} placeholder="Mensaje..." className="form-control w-100 rounded mb-3" rows="10"/>
        <button onClick={(e) => sendMessage()} className="btn bg-white py-2 px-5 d-none d-lg-block mx-auto">Enviar</button>
        <button onClick={(e) => sendMessage()} className="btn bg-white w-100 d-lg-none">Enviar</button>
      </div>
    </div>
  );
}

export default Buzon;
