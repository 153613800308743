import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

const ProgressBar = ({ status }) => {
    const getProgress = (status) => {
      switch (status) {
        case 'pagado':
          return 0;
        case 'enviado':
          return 50;
        case 'entregado':
          return 100;
        default:
          return 0;
      }
    };
  
    return (
      <div className="container">
        <div className="w-100 m-2 rounded px-5">
            <div className="d-flex justify-content-between" style={{marginTop: "-20px"}}>
            <div className={`w-auto rounded-circle p-3 position-relative ${status === 'pagado' || status === 'enviado' || status === 'entregado' ? 'bg-white' : 'border border-1 border-white bg-black'}`} id="step1"></div>
            <div className={"bg-white w-100 my-auto " + (window.innerWidth > 576 ? "mx-5" : "mx-2")} style={{ "height": "5px"}}></div>
            <div className={`w-auto rounded-circle p-3 position-relative ${status === 'enviado' || status === 'entregado' ? 'bg-white' : 'border border-1 border-white bg-black'}`} id="step2"></div>
            <div className={"bg-white w-100 my-auto " + (window.innerWidth > 576 ? "mx-5" : "mx-2")} style={{ "height": "5px"}}></div>
            <div className={`w-auto rounded-circle p-3 position-relative ${status === 'entregado' ? 'bg-white' : 'border border-1 border-white bg-black'}`} id="step3"></div>
            </div>
            <div className="progress-steps d-flex justify-content-between mt-1">
            <div className={`text-white w-auto text-center position-relative`} id="step1">Pendiente</div>
            <div className={`text-white w-auto text-center position-relative`} id="step2">Enviado</div>
            <div className={`text-white w-auto text-center position-relative`} id="step3">Entregado</div>
            </div>
        </div>
      </div>
    );
};

function Compra() {
    const [compra, setCompra] = useState([]);
    let { id } = useParams();

    const currencyOptions = {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
    };

    async function getCompra() {
        await axios.get(process.env.REACT_APP_CMS_URL + "/api/compras?filters[identificador][$eq]="+id+"&populate[carrito][populate][0]=productos").then(async (response) => {
            var compra_response = response?.data?.data[0];
            await axios.get(process.env.REACT_APP_CMS_URL + '/api/carritos?filters[identificador][$eq]='+compra_response?.attributes?.carrito?.data?.attributes?.identificador+'&populate[productos][populate][0]=galeria').then(async (response2) => {
                var compra = {...compra_response}
                compra.attributes.carrito = response2?.data?.data[0];
                setCompra(compra);
            }).catch((error) => {
                console.error(error);
                window.location = "/";
            });
        }).catch((error) => {
          console.error(error);
          window.location = "/";
        });
    }
    
    useEffect(() => {
        getCompra();
    }, []);

    var _items = [];
    compra?.attributes?.carrito?.attributes?.opciones?.forEach(item => {
        const producto = compra?.attributes?.carrito?.attributes?.productos?.data.find(p => p.id === item.producto_id);
        var precio = producto?.attributes?.precio_base;
        var modificadores = [];
        item.modificadores.forEach(modificador => {
            modificadores.push(modificador.valor);
            precio += modificador.precio;
        });
        precio = precio * item.quantity;
        const precio_formatted = new Intl.NumberFormat('en-US', currencyOptions).format(precio);
        _items.push(
            <div className="mb-1">
                <div className="rounded bg-white text-black">
                    <div className="row mx-auto align-items-end">
                        <div className="col-3">
                            <img className="img-fluid" src={process.env.REACT_APP_CMS_URL + producto?.attributes?.galeria?.data[0]?.attributes?.url} alt="" />
                        </div>
                        <div className="col-9 pb-5">
                            <p className="fw-bold">{producto?.attributes?.nombre} x {item?.quantity}</p>
                            <small>{modificadores?.join(" ")}</small>
                        </div>
                    </div>
                </div>
                <div className="rounded bg-white text-black mt-1">
                    <div className="row mx-auto">
                        <div className="offset-2 col-8 text-end">
                            <span className="fw-bold">{precio_formatted}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    return (
        <div className="bg-black text-white text-center">
            <div className="container">
                <section>
                    <h5 className="mt-3 mb-5">Estatus del Pedido</h5>
                    <ProgressBar status={compra?.attributes?.estado}/>
                </section>
                <section>
                    <div className="row mb-3 mx-auto">
                        <div className="col-12 col-lg-6 mt-3">
                            <h5>Productos</h5>
                            {_items}
                        </div>
                        <div className="col-12 col-lg-6 mt-3">
                            <h5>Detalles de la compra</h5>
                            <div className="border border-1 border-white rounded p-2 text-center">
                                <p><strong>Nombre:</strong><br/>{compra?.attributes?.nombre}</p>
                                <p><strong>Domicilio de Entrega:</strong><br/>{compra?.attributes?.domicilio}</p>
                                <p><strong>Teléfono:</strong><br/>{compra?.attributes?.telefono}</p>
                                <p><strong>Monto de la compra:</strong><br/>${compra?.attributes?.precio}</p>
                                <p><strong>Costo de envío:</strong><br/>${compra?.attributes?.envio}</p>
                                <p><strong>Total:</strong><br/>${(compra?.attributes?.precio + compra?.attributes?.envio)}</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Compra;
