
import { initMercadoPago, CardPayment } from '@mercadopago/sdk-react'
import React, { useState } from "react";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';

function PaymentForm(props) {

    const [cont, setCont] = useState(0);
    const [dots, setDots] = useState("");

    initMercadoPago(process.env.REACT_APP_MERCADOPAGO_KEY);

    const initialization = {
        amount: props.amount,
    };

    const notify = (text, type, promise ) => {
        const options = {
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }
        switch(type){
          case "error": toast.error(text, options); break;
          case "success": toast.success(text, options); break;
          case "custom": toast.custom(text); break;
          case "loading": toast.loading(text, options); break;
          case "promise": toast.promise(promise, {
            loading: 'Loading',
            success: (data) => `Successfully saved ${data.name}`,
            error: (err) => `This just happened: ${err.toString()}`,
          }, options); break;
          default: toast(text, options); break;
        }
      }

    const onSubmit = async (formData) => {
        formData.description = props.description;
        formData.purchase = props.purchase;
        formData.jwt = localStorage.getItem("jwt");
        axios.post(process.env.REACT_APP_API_URL + "/api/purchase/process_payment", formData).then((response) => {
            if(response?.data?.status == "success"){
                localStorage.removeItem("cart");
                localStorage.removeItem("compra");
                window.location = "/compra/"+response?.data?.response?.data?.attributes?.identificador;
            }
            else notify("Pago rechazado, intenta con otra tarjeta", "error")
        }).catch((error) => {
            console.error(error);
        });
    };

    const onError = async (error) => {
        console.log("On Error");
        console.log(error);
    };

    const onReady = async () => {
        console.log("On Ready");
    };

    var content;
    if(cont < 12){
        setTimeout(() => {
            if(dots === "...") setDots("");
            else setDots(dots+".");
            setCont(cont+1);
        }, 250);
        content = (<div>Cargando métodos de pago{dots}</div>);
    }
    else content = (<CardPayment
        initialization={initialization}
        onSubmit={onSubmit}
        onReady={onReady}
        onError={onError}
    />);

    return (content);

}

export default PaymentForm;