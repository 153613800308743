import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

function Producto(props) {
  const [producto, setProducto] = useState([]);
  const [modificadores, setModificadores] = useState([]);
  var [quantity, setQuantity] = useState(1);
  let { id } = useParams();

  const notify = (text, type, promise) => {
    const options = {
      style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
      },
    }
    switch (type) {
      case "error": toast.error(text, options); break;
      case "success": toast.success(text, options); break;
      case "custom": toast.custom(text); break;
      case "loading": toast.loading(text, options); break;
      case "promise": toast.promise(promise, {
        loading: 'Loading',
        success: (data) => `Successfully saved ${data.name}`,
        error: (err) => `This just happened: ${err.toString()}`,
      }, options); break;
      default: toast(text, options); break;
    }
  }

  function getProducto() {
    axios.get(process.env.REACT_APP_CMS_URL + "/api/productos/" + id + "?populate=*").then((response) => {
      setProducto(response?.data?.data);
    }).catch((error) => {
      console.error(error);
    });
  }

  useEffect(() => {
    getProducto();
  }, []);

  function setModificador(key, e) {
    var aux_modificadores = [...modificadores];
    var precio = producto?.attributes?.opciones[key]?.opciones[e.target.value];
    var modificador = aux_modificadores.find(m => m.opcion === key);
    if (modificador) {
      modificador.valor = e.target.value;
      modificador.precio = precio;
    }
    else {
      aux_modificadores.push({
        opcion: key,
        valor: e.target.value,
        precio
      })
    }
    setModificadores(aux_modificadores);
  }

  function quantityChange(type) {
    switch (type) {
      case "+": setQuantity(quantity + 1); break;
      case "-": if (quantity > 1) setQuantity(quantity - 1); break;
      default: break;
    }
  }

  function updateCart() {
    var cart = props.cart;
    var valid = true;
    if (!cart?.attributes?.opciones) cart.attributes.opciones = [];

    for (const op in producto?.attributes?.opciones) {
      if (Object.hasOwnProperty.call(producto?.attributes?.opciones, op)) {
        const element = producto?.attributes?.opciones[op];
        if (element.obligatorio === "si") {
          const modificador = modificadores.find(m => m.opcion === op);
          if (!modificador) valid = false;
        }
      }
    }

    if (valid) {
      cart?.attributes?.opciones.push({
        item_id: cart.attributes.opciones.length,
        producto_id: producto.id,
        quantity,
        modificadores
      })
      if(cart?.attributes?.productos){
        id = cart?.attributes?.productos?.data?.find(p => p === producto.id);
        if (!id) cart?.attributes?.productos?.data?.push(producto.id);
      }
      else {
        cart.attributes.productos = {data: [producto.id]};
      }
      props.updateCart(cart, "add");
    }
    else notify("Por favor, selecciona todas las opciones obligatorias", "error");
  }

  var _opciones = [];
  for (let key in producto?.attributes?.opciones) {
    var _options = [];
    for (let op in producto?.attributes?.opciones[key]?.opciones) {
      _options.push(<option value={"" + op}>{op}</option>)
    }
    _opciones.push(
      <div className="form-group mb-3">
        <select className="form-control py-3 px-2" onChange={(e) => setModificador(key, e)}>
          <option disabled selected>{producto?.attributes?.opciones[key]?.etiqueta} {producto?.attributes?.opciones[key]?.obligatorio === "si" ? "(obligatorio)" : ""}</option>
          {_options}
        </select>
      </div>
    );
  }

  const currencyOptions = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  };

  var precio = producto?.attributes?.precio_base;
  modificadores.forEach(modificador => {
    precio += modificador.precio;
  });
  const price = new Intl.NumberFormat('en-US', currencyOptions).format(precio);

  var gal = (<div></div>);
  if (producto?.attributes?.galeria?.data?.length > 1) {
    var _imgs = [];
    var active = true;
    producto?.attributes?.galeria?.data.forEach(img => {
      _imgs.push(
        <div className={"carousel-item" + (active ? " active" : "")} key={"img_" + img.id}>
          <img src={process.env.REACT_APP_CMS_URL + img?.attributes?.url} className="d-block w-100 img-fluid" alt=""></img>
        </div>
      );
      if (active) active = false;
    });
    gal = (
      <div id="carouselProducto" className="carousel slide">
        <div className="carousel-inner">
          {_imgs}
        </div>
        <button className="carousel-control-prev mr-5" type="button" data-bs-target="#carouselProducto" data-bs-slide="prev">
          <span className="carousel-control-prev-icon mr-5 bg-black rounded rounded-circle" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next ml-5" type="button" data-bs-target="#carouselProducto" data-bs-slide="next">
          <span className="carousel-control-next-icon ml-5 bg-black rounded rounded-circle" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    )
  }
  else gal = <img className="img-fluid" src={process.env.REACT_APP_CMS_URL + producto?.attributes?.galeria?.data[0]?.attributes?.url} alt="" />;

  return (
    <div className="container">
      <div className="row mx-auto">
        <div className="col-12 col-lg-5 offset-lg-1">
          <div className="card bg-white rounded rounded-4 mx-1 p-2">
            <div className="card-header">
              <div className="row mx-auto">
                <div className="col-6 offset-3 col-lg-12 offset-lg-0">
                  {gal}
                </div>
              </div>
            </div>
            <div className="card-body" style={{ boxShadow: "0 -10px 10px -5px rgba(0, 0, 0, 0.1)" }}>
              <p className="text-center mb-3"><strong>{producto?.attributes?.nombre}</strong></p>
              <p className="text-center mb-5">{producto?.attributes?.subtitulo}</p>
            </div>
          </div>
          <div className="mt-3">
            {_opciones}
          </div>
          <div className="mt-3 py-3 px-2 bg-white rounded">
            {price}
          </div>
        </div>
        <div className="col-12 col-lg-5 offset-lg-1">
          <div className="mt-3 py-3 px-2 bg-white rounded mb-3 d-lg-none">
            <div className="row mx-auto">
              <div className="col-2 fw-bold btn" onClick={(e) => quantityChange("-")}>
                -
              </div>
              <div className="col-8">
                {quantity}
              </div>
              <div className="col-2 fw-bold btn" onClick={(e) => quantityChange("+")}>
                +
              </div>
            </div>
          </div>
          <button className="btn bg-white w-100 p-3 d-lg-none mb-5" onClick={(e) => updateCart()}>Agregar al carrito</button>
          <h5 className="text-white mb-5">Descriptores de sabor</h5>
          <hr className="text-white mb-5" />
          <p className="text-white text-start mb-5" dangerouslySetInnerHTML={{ __html: producto?.attributes?.descripcion?.replace(/\n/g, '<br />') }}></p>
          <hr className="text-white mb-5" />
          <div className="mt-3 py-3 px-2 bg-white rounded mb-3 d-none d-lg-block">
            <div className="row mx-auto">
              <div className="col-2 fw-bold btn" onClick={(e) => quantityChange("-")}>
                -
              </div>
              <div className="col-8">
                {quantity}
              </div>
              <div className="col-2 fw-bold btn" onClick={(e) => quantityChange("+")}>
                +
              </div>
            </div>
          </div>
          <button className="btn bg-white w-100 p-3 d-none d-lg-block" onClick={(e) => updateCart()}>Agregar al carrito</button>
        </div>
      </div>
    </div>
  );
}

export default Producto;
