function Facturacion() {
  return (
    <div className="bg-black text-white text-center">
      <div className="container">
        <h4 className="mb-5">Facturación</h4>
        <hr className="mb-5"/>
        <h3 className="mb-5">Estimados clientes, les informamos que nuestra facturación es diaria y se realiza al momento de la compra. Envíe un correo a info@cafeeuropa.com para solicitar</h3>
        <img alt="" src="assets/img/icons/facturacion.png"/>
      </div>
    </div>
  );
}

export default Facturacion;
