import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Home() {
  const [sliders, setSliders] = useState([]);
  const [productos, setProductos] = useState([]);

  var _sliders = [];
  var _productos = [];

  function getSliders() {
    axios
      .get(process.env.REACT_APP_CMS_URL + "/api/sliders?populate=*")
      .then((response) => {
        setSliders(response?.data?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getProductos() {
    axios
      .get(process.env.REACT_APP_CMS_URL + "/api/productos?populate=*")
      .then((response) => {
        setProductos(response?.data?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    getSliders();
    getProductos();
  }, []);

  let active = true;
  sliders.forEach((slider) => {
    _sliders.push(
      <div key={"slider" + slider?.id} className={"carousel-item" + (active ? " active" : "")}>
        <Link to={slider?.attributes?.link}>
          <img src={process.env.REACT_APP_CMS_URL + slider?.attributes?.imagen?.data?.attributes?.url} className="d-block mx-auto" style={{maxHeight: "168px"}} alt="..." />
        </Link>
      </div >
    );
    if (active) active = false;
  });

  for (let i = productos.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [productos[i], productos[j]] = [productos[j], productos[i]];
  }
  var prods = productos.slice(0, 3);

  let cont = 0;
  prods.forEach((producto) => {
    _productos.push(
      <div className={"col-6 col-md-4 col-lg-4 p-1"+(cont === 2 ? " d-none d-md-block" : "")}>
        <Link className="text-decoration-none" to={"/tienda/" + producto.id}>
          <div className="card bg-white rounded rounded-4 p-2">
            <div className="d-none d-lg-block" style={{height:"300px"}}>
              <img
                className="img-fluid" style={{maxHeight: "300px"}}
                src={process.env.REACT_APP_CMS_URL + producto.attributes.galeria.data[0].attributes.url}
                alt={producto.attributes.nombre}
              />
            </div>
            <div className="d-lg-none" style={{height:"200px"}}>
              <img
                className="img-fluid"
                src={process.env.REACT_APP_CMS_URL + producto.attributes.galeria.data[0].attributes.url}
                alt={producto.attributes.nombre}
              />
            </div>
            <div className="d-none d-lg-block" style={{height: "150px", boxShadow: "0 -10px 10px -5px rgba(0, 0, 0, 0.1)"}}>
              <p className="text-center mb-3 flex-grow-1">
                <strong>{producto.attributes.nombre}</strong>
              </p>
              <p className="text-center mb-5">{producto.attributes.subtitulo}</p>
            </div>
            <div className="d-lg-none" style={{height: "100px", boxShadow: "0 -10px 10px -5px rgba(0, 0, 0, 0.1)"}}>
              <p className="text-center mb-3 flex-grow-1">
                <strong>{producto.attributes.nombre}</strong>
              </p>
              <p className="text-center mb-5">{producto.attributes.subtitulo}</p>
            </div>
          </div>
        </Link>
      </div>
    );
    if (producto) producto = false;
    cont ++;
  });

  return (
    <div className="bg-black">
      <section
        style={{
          minHeight: "944px",
          backgroundImage: "url('./assets/img/home/hablemos-section.png')",
          backgroundSize: "cover",
          backgroundPositionX: "center"
        }}
      >
        <div className="container p-5">
          {/*<div id="carousel" className="carousel slide">
            <div className="carousel-inner">
              {_sliders}
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carousel"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carousel"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>*/}
          <div className="row mx-auto">
            <div className="col-12 col-lg-4 offset-lg-8">
              <div className="d-none d-md-block d-lg-none py-5">&nbsp;</div>
              <h5 className="text-white text-start my-5 d-none d-lg-block">Hablemos de café</h5>
              <h5 className="text-white my-5 d-lg-none">Hablemos de café</h5>
              <p className="text-white d-none d-lg-block" style={{ textAlign: "justify" }}>
                Tenemos la tradición de hacer las cosas con pasión. Nuestra
                historia, al igual que nuestro café, es excepcional.
              </p>
              <p className="mb-5 pb-5">
                <small className="text-white d-lg-none mb-5 pb-5">
                  Tenemos la tradición de hacer las cosas con pasión. Nuestra
                  historia, al igual que nuestro café, es excepcional.
                </small>
              </p>
              <div className="text-end my-5 d-none d-lg-block">
                <Link to="/hablemos" className="btn bg-transparent border-white text-white">
                  Conoce más
                </Link>
              </div>
              <div className="d-lg-none pt-5" >
                <Link to="/hablemos" style={{backgroundColor: "rgba(255, 255, 255, 0.1)"}} className="border p-2 border-white border-1 text-decoration-none border-white text-white">
                  Conoce más
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        style={{
          minHeight: "944px",
          backgroundImage: "url('./assets/img/home/nosotros-section.png')",
          backgroundSize: "cover",
          backgroundPositionX: window.innerWidth > 576 ? "center" : "-150px"
        }}
      >
        <div className="container py-5">
          <div className="row mx-auto">
            <div className="col-lg-6 offset-lg-6">
              <h5 className="text-white text-end d-none d-lg-block my-5">Sobre Nosotros</h5>
              <h5 className="text-white text-center d-lg-none my-5">Sobre Nosotros</h5>
              <p className="text-white text-end d-none d-lg-block">
                Nuestra historia comienza en el año 1957 en la ciudad de
                Morelia, Michoacán, México, cuando se establece un expendio con
                tostador de café en un local ubicado en el “Hotel Europa” en el
                centro de la capital Michoacana.
              </p>
              <p className="text-white text-end d-none d-lg-block mb-5">
                A partir de este momento, nos posicionamos como proveedor
                preferente de café para el consumo particular, así como de
                restaurantes, hoteles, oficinas, entre otros.
              </p>
              <p className="text-white text-center d-lg-none">
                <small>
                  Nuestra historia comienza en el año 1957 en la ciudad de
                  Morelia, Michoacán, México, cuando se establece un expendio con
                  tostador de café en un local ubicado en el “Hotel Europa” en el
                  centro de la capital Michoacana.
                </small>
              </p>
              <p className="text-white text-center d-lg-none mb-5">
                <small>
                  A partir de este momento, nos posicionamos como proveedor
                  preferente de café para el consumo particular, así como de
                  restaurantes, hoteles, oficinas, entre otros.
                </small>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
      
        style={{
          minHeight: "944px",
          backgroundImage: "url('./assets/img/home/menu-section.png')",
          backgroundSize: "cover",
          backgroundPositionX: "center"
        }}
      >
        <div className="container py-5">
          <div className="row mx-auto">
            <div className="col-lg-4">
              <h5 className="text-white my-5">Nuestro Menú</h5>
              <p className="text-white d-none d-lg-block" style={{ textAlign: "justify" }}>
                Desayunos, comida, postres, bar, barra de especialidad, bebidas
                alternativas y más.
              </p>
              <p className="text-white text-center d-lg-none pb-5" style={{ textAlign: "justify" }}>
                Desayunos, comida, postres, bar, barra de especialidad, bebidas
                alternativas y más.
              </p>
              <div className="text-end my-5 d-none d-lg-block">
                <Link to="/menu" className="btn bg-transparent border-white text-white">
                  Conoce más
                </Link>
              </div>
              <div className="text-center my-5 pt-5 d-lg-none">
                <Link to="/menu" style={{backgroundColor: "rgba(255, 255, 255, 0.1)"}} className="border p-2 border-white border-1 text-decoration-none border-white text-white">
                  Conoce más
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="d-none d-lg-block"
        style={{
          minHeight: "944px",
          backgroundImage: "url('./assets/img/home/sucursales-section.png')",
          backgroundSize: "cover",
        }}
      >
        <div className="container py-5">
          <div className="row mx-auto">
            <div className="col-lg-5 offset-lg-7">
              <h5 className="text-white text-end my-5">Nuestras sucursales</h5>
              <p
                className="text-white text-end"
                style={{ textAlign: "justify" }}
              >
                Conoce nuestras sucursales:
                <br />
                Barra de especialidad, Restaurant-Bar o Drive-Thru
              </p>
              <div className="text-end my-5">
                <Link to="/sucursales" className="btn bg-transparent border-white text-white">
                  <img
                    alt=""
                    className="me-3"
                    src="/assets/img/icons/location.png"
                  />
                  Encuentra la sucursal más cercana.
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="d-lg-none"
        style={{
          minHeight: "944px",
          backgroundImage: "url('./assets/img/home/sucursales-section.png')",
          backgroundSize: "cover",
          backgroundPosition: "center"
        }}
      >
        <div className="container py-5">
          <div className="row mx-auto pt-5">
            <div className="col-lg-5 offset-lg-7 pt-5">
              <h5 className="text-white text-center my-5">Nuestras sucursales</h5>
              <p
                className="text-white text-center"
              >
                Conoce nuestras sucursales:
                <br />
                Barra de especialidad, Restaurant-Bar o Drive-Thru
              </p>
              <div className="text-center my-5">
                <Link to="/sucursales" style={{backgroundColor: "rgba(255, 255, 255, 0.1)"}} className="border p-2 border-white border-1 text-decoration-none border-white text-white">
                  Ver sucursales
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="container">
          <h4 className="text-white text-center mb-4 mt-5">Tienda en Línea</h4>
          <small className="text-white mb-5">Compra en línea tus productos favoritos</small>
          <div className="row mx-auto mt-5">
            {_productos}
            <div className="col-12 col-lg-4 offset-lg-4 my-5 py-5">
              <Link to="/tienda"  style={{backgroundColor: "rgba(255, 255, 255, 0.1)"}} className="border p-2 border-white border-1 text-decoration-none border-white text-white d-lg-none mt-5 px-4">
                Ver Todo
              </Link>
              <Link to="/tienda" className="btn bg-transparent border-white text-white w-100 mt-5 d-none d-lg-block">
                Ver Todo
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
