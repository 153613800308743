function Distribuidora() {
  return (
    <div className="bg-black text-white text-center">
      <div className="container p-5">
        <h4 className="mb-5 d-none d-lg-block fw-semibold">Conoce Nuestra Distribuidora</h4>
        <p className="mb-5 d-lg-none fw-semibold">Conoce Nuestra Distribuidora</p>
        <div className="row mx-auto align-items-end">
          <div className="col-lg-8 pb-4">
            <h5 className="mb-5 d-lg-none">Inno Barras</h5>
            <img className="img-fluid rounded" alt="Inno Barras" src="assets/img/distribuidora/inno-barras.png" />
          </div>
          <div className="col-lg-4">
            <p className="mb-5 d-none d-lg-block">Inno Barras</p>
            <p className="mb-5">¿Te interesa abrir una cafetería? Tenemos todo lo que necesitas en nuestra distribuidora.</p>
            <a className="btn bg-white w-100 p-3" href="http://innobarras.com.mx">Ir a la página</a>
          </div>
        </div>
      </div>
    </div>
  );
}

//uzgr nbmp zomr zaji

export default Distribuidora;
